import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { parse } from 'date-fns';
export interface ICourseRegistration {
    registrationDate: Date;
    startDate: Date;
    emailBody: string;
    status?: string;
    caseworker?: string;
    title: string;
    courseId: string;
    price: string | number;
    totalPrice: string;
    heardFrom: string;
    participants: string | number;
    firmName: string;
    contactPerson: string;
    contactPersonEmail: string;
    cvr: string;
    phonenumber: string;
    ean: string;
    comment: string;
    week: string;
    year: string;
    month: string;
}

export const danishMonths = ["januar", "februar", "mart", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"];

export const mainCourses = [
    "Power BI",
    "Power BI",
    "SQL",
    "EXCEL",
    ""
]

export const exportToExcel = (jsonData: any[], fileName: string) => {
    // Convert JSON to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Create a Blob and save the file
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(data, `${fileName}.xlsx`);
};

export const getSECourseName = (emailBody: string) => {

    if (emailBody.indexOf('Kursus: ') > -1) {
        return emailBody.substring(emailBody.indexOf('Kursus: ') + 7, emailBody.indexOf('<br />Kursus Id:'));
    } else {
        return emailBody.substring(emailBody.indexOf('Test: '), emailBody.indexOf('<br />Test Id:'));
    }

}
export const getSECoursePrice = (emailBody: string) => {
    if (emailBody.indexOf('Samlet pris: ') > -1) {
        const regex = /Samlet pris: \s*(.*?) kr. ex moms<br /i;
        const match = emailBody.match(regex);
        if (match && match.length > 1) {
            const searchText = match[1].trim();
            return searchText;
        }
    } else {
        return "-";
    }

}
export const trimPriceString = (price: string, obj: any) => {
    const regex = /(?<=\.)[^,]+/;
    const deltagere = parseInt(getPeoplenetDeltagere(obj));
    if (price) {

        if (price.indexOf(",") <= -1) {
            const filteredPrice = price.replace(/\./g, '');
            const newPrice = parseFloat(filteredPrice) * deltagere;
            return newPrice;
        }
        const match = price.match(regex);
        if (match) {
            const matchPrice = match[0].indexOf(".") > -1 ? match[0].replace(/\./g, '') : match[0];
            const newPrice = parseFloat(matchPrice) * deltagere;
            return newPrice;
        } else {
            const newPrice = parseFloat(price) * deltagere;
            return parseFloat(price) * deltagere;
        }

    } else {
        return "-";
    }

}

export const getPeoplenetDeltagere = (obj: any) => {
    if (obj?.participants) {
        const regex = /@/g;
        const match = obj.participants.match(regex);

        if (match && match.length >= 1) {
            return match?.length;
        } else {
            return 1;
        }
    } else {
        return 1;
    }
}


export const getSECourseHeardFrom = (emailBody: string) => {

    if (emailBody.indexOf('Har hørt om 4D fra:') > -1) {

        const regex = /Har hørt om 4D fra:\s*(.*?)<br/i;
        const match = emailBody.match(regex);

        if (match && match.length > 1) {
            const searchText = match[1].trim();

            if (searchText == "Hvor har du hørt om 4D?") {
                return "-"
            }
            return searchText;
        }

    } else {
        return "-"
    }

}

export const isRegisteredEmailInternal = (emailBody: string) => {
    if (emailBody.indexOf('Email:') > -1) {

        const deltagereStr = emailBody.substring(emailBody.indexOf('Email:') + 6, emailBody.indexOf('<br />Afdeling'));
        const regex = /@([\w-]+)\./;
        const match = deltagereStr.match(regex);

        if (match && match.length >= 1) {

            return match[1] === "peoplenet";
        } else {
            return false
        }

    } else {
        return false
    }

}
export const isRegisteredEmailInternalPeoplenet = (emailBody: string) => {

    if (emailBody) {

        const regex = /@([\w-]+)/;
        const match = emailBody.match(regex);

        if (match && match.length >= 1) {
            return (match[1] === "people" || match[1] === "peoplenet");
        } else {
            return false
        }

    } else {
        return false
    }

}

export const getSECoursedeltagere = (emailBody: string) => {

    if (emailBody.indexOf('Deltagere:') > -1) {

        const deltagereStr = emailBody.substring(emailBody.indexOf('Deltagere:') + 10, emailBody.indexOf('<br />Klippekort nr:'));
        const regex = /@/g;
        const match = deltagereStr.match(regex);

        if (match && match.length >= 1) {
            return match?.length;
        } else {
            return "-"
        }

    } else {
        return "-"
    }

}

export const getSEBodyDetail = (emailBody: string, start: string, end: string) => {
    if (emailBody.indexOf(start) > -1) {
        const regex = new RegExp(`${start}:\\s*(.*?)${end}`, "i");
        const match = emailBody.match(regex);
        if (match && match.length > 1) {
            const searchText = match[1].trim();
            return searchText;
        }
    } else {
        return "-";
    }
}


export const create4DRegistrationObject = (data: any[]): ICourseRegistration[] => {
    let sortedDates: ICourseRegistration[] = [];
    if (data && data.length > 0) {
        sortedDates = data.map((obj: any) => {
            const startDate = getSEBodyDetail(obj.seBody, "Startdato", "- ");

            return {
                emailBody: obj.seBody,
                registrationDate: new Date(obj.seDateTime),
                startDate: startDate && startDate.length <= 10 ? parse(startDate, "dd-MM-yyyy", new Date()) : parse(getSEBodyDetail(obj.seBody, "Startdato", "<"), "dd-MM-yyyy", new Date()),
                status: obj?.seStatus,
                caseworker: obj?.seBehandler,
                courseId: obj?.seSeid,
                title: getSECourseName(obj.seBody),
                price: getSEBodyDetail(obj.seBody, "Oplyst pris", "kr. ex moms<br />"),
                totalPrice: getSECoursePrice(obj.seBody),
                heardFrom: getSECourseHeardFrom(obj.seBody),
                participants: getSECoursedeltagere(obj.seBody),
                cvr: getSEBodyDetail(obj.seBody, "CVR nr.", "<br />"),
                phonenumber: getSEBodyDetail(obj.seBody, "Telefon", "<br />"),
                ean: getSEBodyDetail(obj.seBody, "EAN", "<br />"),
                comment: getSEBodyDetail(obj.seBody, "Kommentar", "<br />"),
                firmName: getSEBodyDetail(obj.seBody, "Firmanavn", "<br />"),
                contactPerson: getSEBodyDetail(obj.seBody, "Kontaktperson", "<br />"),
                contactPersonEmail: getSEBodyDetail(obj.seBody, "Email", "<br />"),
                week: "Uge " + moment(obj.seDateTime).isoWeek().toString(),
                year: moment(obj.seDateTime).weekYear().toString() + " ",
                month: moment(obj.seDateTime).month().toString()
            }
        })
    }

    return sortedDates
}
export const createPeoplenetRegistrationObject = (data: any[]): ICourseRegistration[] => {
    let sortedDates: ICourseRegistration[] = [];
     
    if (data && data.length > 0) {
        sortedDates = data.map((obj: any) => {
            let coursePrice = obj.coursePrice ? trimPriceString(obj.coursePrice, obj) : "0";
            let year = moment(obj.createdTime).weekYear().toString() + " ";
            if (!obj.coursePrice) {
                if (obj.courseTitle.includes("Power Automate") && !obj.courseTitle.includes("Power Apps")) {
                    coursePrice = "13500";
                } else if (obj.courseTitle.includes("Microsoft Azure FinOps")) {
                    coursePrice = "18800";
                } else if (year == "2023 ") {
                    coursePrice = data.find((cp: any) => obj.courseTitle.includes(cp.title))?.price;
                }
            }
            return {
                emailBody: null,
                registrationDate: new Date(obj.createdTime),
                startDate: new Date(obj.coursedate),
                status: obj?.seStatus,
                caseworker: obj?.manager,
                courseId: "Course date id: " + obj?.coursedateId,
                title: obj?.courseTitle,
                price: (coursePrice && typeof (coursePrice) == "string") ? coursePrice.replace(".", "") : coursePrice,
                totalPrice: null,
                heardFrom: obj.referredby,
                participants: getPeoplenetDeltagere(obj),
                cvr: obj.cvr,
                phonenumber: obj.phone,
                ean: null,
                comment: obj.comments,
                firmName: obj.company,
                contactPerson: obj.firstName,
                contactPersonEmail: obj.email,
                week: "Uge " + moment(obj.seDateTime).isoWeek().toString(),
                year: year,
                month: moment(obj.createdTime).month().toString()
            }
        })
    }

    return sortedDates
}
import { useContext } from "react";
import KurserContext from "../KurserContext";

interface RequireRoleProps {
    roles: string[];
    children: React.ReactNode;
}

const RequireRole = ({ roles, children }: RequireRoleProps) => {
    const { roles: userRoles } = useContext(KurserContext);

    const hasAccess = roles.some(role => userRoles.includes(role));

    return hasAccess ? <>{children}</> : null;
};

export default RequireRole;

import { Label, Panel, PanelType, PrimaryButton, TextField } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { ICourseProgram, ICourseType } from "./DataTable";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getCourseTypes, updateCourseContent } from "../api";
import "../styles/PricePanel.css";

export default function PricePanel(props: { courseType: ICourseType, program: ICourseProgram, onDismiss: () => void; }): JSX.Element {
    const [courseTypes, setCourseTypes] = useState<ICourseType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const inputPrice = useRef<HTMLInputElement>(null);
    const inputDuration = useRef<HTMLInputElement>(null);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
        });
    }, [props])

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            if (props.program) {
                getCourseTypes(response.accessToken, props.program?.programnavnID).then((data: any) => {
                    const type = data.data.find((type: any) => type.kursusTitel === props.courseType.kursusTitel);
                    setCourseTypes(type);
                    setIsLoading(false);
                })
            }
        });
    }, []);


    const onUpdatePrice = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (courseTypes !== null) {

            try {
                if (courseTypes) {
                    const newTypeObj: ICourseType = {
                        ...props.courseType,
                        kursusTitel: props.courseType.kursusTitel,
                        kursustypeID: props.courseType.kursustypeID,
                        pris: courseTypes.pris,
                        programnavnID: props.program.programnavnID,
                        varighed: courseTypes.varighed,
                        indhold: courseTypes.indhold,
                        duLaerer: courseTypes.duLaerer,
                        duBoer: courseTypes.duBoer,
                        duFaar: courseTypes.duFaar,
                        indledning: courseTypes.indledning,
                        kursusmateriale: courseTypes.kursusmateriale,
                        kursusmål: courseTypes.kursusmål,
                        deltagere: courseTypes.deltagere,
                        firmaOsPris: courseTypes.firmaOsPris,
                        firmaJerPris: courseTypes.firmaJerPris,
                    }

                    const savedCourse = await updateCourseContent(token, newTypeObj);
                    if (savedCourse && savedCourse.data) {
                        setError("Kursus gemt!");
                    }
                    setCourseTypes(newTypeObj);
                }

            } catch (error) {
                console.log(error);
            }
        } else {
            setError("Kan ikke finde kursus");
        }


    }

    return (
        <Panel
            headerText={props?.courseType.kursusTitel}
            type={PanelType.medium}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            className="pricePanel"
        >
            <p>Administrer priser</p>

            {!isLoading && courseTypes !== null && (
                <div className="container">
                    {courseTypes && (
                        <>
                            <div>
                                <TextField
                                    label="Pris pr. dag"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseTypes((prev) => ({ ...prev, pris: Number(e.target.value) }))}
                                    className="courseTextField"
                                    value={courseTypes.pris?.toString()}
                                />
                                <span>{courseTypes.pris?.toLocaleString('da-DK')} DKK</span>
                            </div>
                            <div className="priceInfoContainer">
                                {/* // <span className="price" id="priceInfo">{courseTypes.pris} kr.</span> */}
                                <div className="priceInfo">
                                    <TextField
                                        label="Firma pris hos os pr. dag"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseTypes((prev) => ({ ...prev, firmaOsPris: Number(e.target.value) }))}
                                        className="courseTextField"
                                        value={courseTypes.firmaOsPris?.toString()}
                                    />
                                    <span>{courseTypes.firmaOsPris?.toLocaleString('da-DK')} DKK</span>
                                </div>
                                <div className="priceInfo">
                                    <TextField
                                        label="Firma pris hos dem pr. dag"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseTypes((prev) => ({ ...prev, firmaJerPris: Number(e.target.value) }))}
                                        className="courseTextField"
                                        value={courseTypes.firmaJerPris?.toString()}
                                    />
                                    <span>{courseTypes.firmaJerPris.toLocaleString('da-DK')} DKK</span>
                                </div>
                            </div>
                        </>
                    )}

                    <form>
                        <div className="inputContainer">
                            <PrimaryButton className="btnSave" onClick={onUpdatePrice}>Gem</PrimaryButton>
                        </div>

                    </form>
                    {error != "" && (
                        <Alert severity="warning">{error}</Alert>
                    )}

                </div>

            )}


        </Panel>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/TextEditor.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'link',
  'image',
];

const TextEditor = ({ initialContent, onContentChange }) => {
  const [content, setContent] = useState(initialContent || '');

  useEffect(() => {
    if (initialContent !== content) {
      setContent(initialContent || '');
    }
  }, [initialContent]);

  const handleChange = (value, delta, source) => {
    if (source === 'user') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');

      doc.querySelectorAll('ul').forEach((ul) => {
        if (!ul.classList.contains('contentlist')) {
          ul.classList.add('contentlist');
        }
      });

      setContent(value);
      onContentChange(value);
    }
  };

  return (
    <div className='textInput'>
      <ReactQuill
        value={content}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default TextEditor;

import { Panel, PanelType, PrimaryButton, Dropdown, IDropdownOption, Checkbox, TextField, Pivot, PivotItem, Label, MessageBar, MessageBarType, Dialog, DialogType, DialogFooter, DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import Alert from "@mui/material/Alert";
import { IProgram, ICourseType, IProducttype } from "./DataTable";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {
    createNewProgram,
    getProducttypes,
    getProgramgroups,
    getPrograms,
} from "../api";
import "../styles/ContentPanel.css";
import TextEditor from "./TextEditor";
import ProgramContentEditor from "./ProgramContentEditor";

export default function NewProgramPanel(props: { programs: IProgram[]; producttype?: IProducttype; onDismiss: (newProgram) => void; }): JSX.Element {
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [programs, setPrograms] = useState<IProgram[]>([]);
    const [isDialogLoading, setIsDialogLoading] = useState(false);
    const [program, setProgram] = useState<IProgram>({
        programnavnID: 0,
        navn: props.producttype?.navn || "",
        beskrivelse: "<h2>Velkommen til vores <strong>Kursus</strong></h2>",
        beskrivelseCert: "",
        beskrivelseTests: "",
        beskrivelseSeminarer: "",
        beskrivelseAfter: "",
        programRent: "",
        programRentAlt: "",
        kursus: true,
        visPåKursusOversigt: false,
        test: false,
        cert: false,
        konsulent: false,
        udvikling: false,
        programgruppeID: 81154,
        kursuskategoriID: null,
        produkttypeID: props.producttype?.produkttypeID || 0,
        stopside: true,
        inaktiv: false,
    });

    const [dropdownOptions, setDropdownOptions] = useState<{
        produkttypeID: IDropdownOption[];
        programgruppeID: IDropdownOption[];
    }>({
        produkttypeID: [],
        programgruppeID: [],
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error] = useState<string>("");
    const [producttypes, setProducttypes] = useState<IProducttype[]>([]);

    useEffect(() => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then(async (response) => {
                setToken(response.accessToken);
                try {
                    const [produkttyper, programgrupper, programs]: any = await Promise.all([
                        getProducttypes(response.accessToken),
                        getProgramgroups(response.accessToken),
                        getPrograms(response.accessToken, false, true),
                    ]);
                    setPrograms(programs.data);
                    setProducttypes(produkttyper.data);
                    const grouped = props.programs.reduce((acc: { [key: number]: IProgram[] }, program: IProgram) => {
                        if (!acc[program.produkttypeID]) {
                            acc[program.produkttypeID] = [];
                        }
                        acc[program.produkttypeID].push(program);
                        return acc;
                    }, {});
                    const filteredProducttypes = produkttyper.data.filter((v: { produkttypeID: number }) => grouped[v.produkttypeID] && grouped[v.produkttypeID].length > 0);
                    const extraProducttypes = produkttyper.data.filter((v: { produkttypeID: number; navn: string }) =>
                        programs.data.some((p: IProgram) => p.produkttypeID === v.produkttypeID && p.navn === v.navn)
                    );

                    console.log(programs, producttypes)
                    const uniqueProducttypes: { produkttypeID: number; navn: string }[] = [];
                    const seenIDs = new Set<number>();

                    [...filteredProducttypes, ...extraProducttypes].forEach((item) => {
                        if (!seenIDs.has(item.produkttypeID)) {
                            seenIDs.add(item.produkttypeID);
                            uniqueProducttypes.push(item);
                        }
                    });


                    setDropdownOptions({
                        produkttypeID: (props.producttype !== null ? produkttyper.data : uniqueProducttypes).map((v: { produkttypeID: number; navn: string }) => ({ key: v.produkttypeID, text: v.navn })),
                        programgruppeID: programgrupper.data.map((l: { programgruppeID: number; navn: string }) => ({ key: l.programgruppeID, text: l.navn }))
                    });
                } catch (err) {
                    console.error("Kan ikke fetche dropdown options", err);
                } finally {
                    setIsLoading(false);
                }
            });
    }, [accounts, instance]);

    const onDropdownChange = (key: string, option?: IDropdownOption): void => {
        setProgram((prev) => ({
            ...prev,
            [key]: option?.key,
        }));
    };

    const validateFields = () => {
        if (!program.navn || program.navn.trim() === "") {
            setWarningMessage("Program navn er påkrævet.");
            return false;
        }
        if (!program.beskrivelse || program.beskrivelse.trim() === "") {
            setWarningMessage("Beskrivelse er påkrævet.");
            return false;
        }
        if (!program.beskrivelseAfter || program.beskrivelseAfter.trim() === "") {
            setWarningMessage("Beskrivelse efter er påkrævet.");
            return false;
        }
        if (program.produkttypeID === 0) {
            setWarningMessage("Vælg en produkt type.");
            return false;
        }
        return true;
    };

    const onSaveProgram = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setWarningMessage(null);
        setSuccessMessage(null);
        if (!validateFields()) return;

        setIsDialogVisible(true);
    };

    const confirmSaveProgram = async () => {
        setIsDialogLoading(true);

        try {
            const newProgram = {
                ...program,
                programRent: program.navn,
                programRentAlt: program.navn,
            }
            const savedProgram = await createNewProgram(token, newProgram);
            if (savedProgram && savedProgram.data && props.producttype == null) {
                props.onDismiss(savedProgram.data);
                setSuccessMessage("Program er blevet oprettet!");
                setProgram(null);
            } else {
                props.onDismiss(null);
            }
            setIsDialogLoading(false);
        } catch (error) {
            console.error(error);
            setWarningMessage("Fejl ved oprettelse af program.");
        } finally {
            setIsDialogLoading(false);
        }
    };
    const selectedProductType = producttypes.find(pt => pt.produkttypeID === program.produkttypeID)?.navn || "Ukendt";

    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss(null)}
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    minHeight: 0,
                },
                scrollableContent: {
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 0,
                    overflowY: "auto",
                    height: "100%",
                },
            }}
        >
            <div
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 100,
                    backgroundColor: "white",
                    paddingBottom: "1rem",
                    marginTop: "-18px",
                    paddingTop: "1rem",
                    width: "90%",
                }}
            >
                <h4 style={{ margin: 0 }}>{props.producttype ? "Tilføj produkt type" : "Tilføj nyt program"}</h4>
            </div>
            {!isLoading && (
                <>
                    <Pivot>
                        <PivotItem headerText="Program" style={{ height: '100%' }} alwaysRender>
                            <div className="container">
                                <TextField
                                    label={props.producttype ? "Produkt navn" : "Program navn"}
                                    required
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProgram((prev) => ({ ...prev, navn: e.target.value }))}
                                    className="programTextField"
                                    value={program.navn}
                                    disabled={props.producttype !== null}

                                />
                                <Dropdown
                                    className="dropdown"
                                    label="Programgruppe"
                                    placeholder="Vælg programgruppe"
                                    options={dropdownOptions.programgruppeID}
                                    onChange={(e, option) => onDropdownChange("programgruppeID", option)}
                                    selectedKey={props.producttype !== null ? 81152 : 81154}
                                    disabled
                                    required
                                />
                                <Dropdown
                                    className="dropdown"
                                    label="Produkt type"
                                    placeholder={props.producttype !== null ? selectedProductType : "Vælg produkt type"}
                                    options={dropdownOptions.produkttypeID}
                                    onChange={(e, option) => onDropdownChange("produkttypeID", option)}
                                    selectedKey={program.produkttypeID}
                                    defaultSelectedKey={program.produkttypeID}
                                    required
                                    style={{ marginBottom: "2rem" }}
                                    disabled={props.producttype !== null}
                                />
                                <Label style={{ marginBottom: "2rem" }}>
                                    URL : {selectedProductType ? `https://4d.dk/kurser/${selectedProductType.toLowerCase().replace(/\s+/g, "-")}/${!props.producttype ? program.navn.toLowerCase().replace(/\s+/g, "-") + "/" : ""}` : "Loading..."}
                                </Label>

                                <Label>Hvilke menupunkter skal vises på program siden?</Label>
                                <Checkbox
                                    label="Kursus"
                                    onChange={(e, checked) => setProgram((prev) => ({ ...prev, kursus: checked ?? false }))}
                                    className="programCheckbox"
                                    checked={program.kursus}
                                    disabled
                                />
                                <Checkbox
                                    label="Test"
                                    onChange={(e, checked) => setProgram((prev) => ({ ...prev, test: checked ?? false }))}
                                    className="programCheckbox"
                                    checked={program.test}
                                    disabled
                                />
                                <Checkbox
                                    label="Certificering"
                                    onChange={(e, checked) => setProgram((prev) => ({ ...prev, cert: checked ?? false }))}
                                    className="programCheckbox"
                                    checked={program.cert}
                                    disabled
                                />
                            </div>
                        </PivotItem>
                        {program.kursus && (
                            <PivotItem headerText="Indhold" style={{ height: '100%' }} alwaysRender>
                                <div className="container">
                                    <Label>Beskrivelse</Label>
                                    <ProgramContentEditor
                                        initialHtml={program.beskrivelse}
                                        onContentChange={(updatedContent) =>
                                            setProgram((prev) => ({
                                                ...prev,
                                                beskrivelse: updatedContent,
                                            }))
                                        }
                                    />
                                    <Label>Beskrivelse efter kursusoversigt</Label>
                                    <ProgramContentEditor
                                        initialHtml={program.beskrivelseAfter}
                                        onContentChange={(updatedContent) =>
                                            setProgram((prev) => ({
                                                ...prev,
                                                beskrivelseAfter: updatedContent,
                                            }))
                                        }
                                    />
                                </div>
                            </PivotItem>
                        )}
                        {program.cert && (
                            <PivotItem headerText="Cert indhold" style={{ height: '100%' }} alwaysRender>
                                <Label>Beskrivelse Cert</Label>
                                <TextEditor
                                    initialContent={program.beskrivelseCert}
                                    onContentChange={(updatedContent) =>
                                        setProgram((prev) => ({
                                            ...prev,
                                            beskrivelseCert: updatedContent,
                                        }))
                                    }
                                />
                            </PivotItem>
                        )}
                        {program.test && (
                            <PivotItem headerText="Test indhold" style={{ height: '100%' }} alwaysRender>
                                <Label>Beskrivelse Tests</Label>
                                <TextEditor
                                    initialContent={program.beskrivelseTests}
                                    onContentChange={(updatedContent) =>
                                        setProgram((prev) => ({
                                            ...prev,
                                            beskrivelseTests: updatedContent,
                                        }))
                                    }
                                />
                            </PivotItem>
                        )}
                    </Pivot>

                    <div className="btnSaveContainer">
                        {warningMessage && (
                            <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setWarningMessage(null)}>
                                {warningMessage}
                            </MessageBar>
                        )}

                        {successMessage && (
                            <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessMessage(null)}>
                                {successMessage}
                            </MessageBar>
                        )}

                        <Dialog
                            hidden={!isDialogVisible}
                            onDismiss={() => setIsDialogVisible(false)}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: "Bekræft handling",
                                subText: "Er du sikker på, at du vil tilføje dette program?",
                            }}
                            modalProps={{ isBlocking: true }}
                        >
                            {isDialogLoading ? (
                                <Spinner size={SpinnerSize.medium} />
                            ) : (
                                <DialogFooter>
                                    <PrimaryButton
                                        text="Ja, tilføj" onClick={confirmSaveProgram} disabled={isDialogLoading || successMessage !== null}
                                        styles={{
                                            root: { backgroundColor: isDialogLoading || successMessage ? "#f3f3f3" : undefined }
                                        }} />
                                    {isDialogLoading ? (
                                        <DefaultButton text="Annuller" onClick={() => setIsDialogVisible(false)} />
                                    ) : (
                                        <DefaultButton text="Luk" onClick={() => setIsDialogVisible(false)} />
                                    )}
                                </DialogFooter>
                            )}
                            {successMessage && (
                                <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessMessage(null)}>
                                    {successMessage}
                                </MessageBar>
                            )}
                        </Dialog>

                        {!successMessage ? (
                            <PrimaryButton text="Tilføj program" className="btnSave" onClick={onSaveProgram} />
                        ) : (
                            <PrimaryButton
                                text="Luk"
                                className="btnSave"
                                onClick={() => props.onDismiss(null)}
                                styles={{ root: { backgroundColor: "#f3f3f3", color: "#000" } }}
                            />
                        )}
                    </div>
                    {error && <Alert severity="warning">{error}</Alert>}
                </>
            )}
        </Panel>
    );
}

import { ITextField, Label, Panel, PanelType, PrimaryButton, TextField, Pivot, PivotItem } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { IProgram } from "./DataTable";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { updateProgramContent } from "../api";
import "../styles/ContentPanel.css";
import TextEditor from "./TextEditor";
import ProgramContentEditor from "./ProgramContentEditor";

export default function ProgramContentPanel(props: { programContent: IProgram, onDismiss: () => void; }): JSX.Element {
    const [programContent, setProgramContent] = useState<IProgram | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
        });
    }, [props])

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            if (props.programContent) {
                const parsedContent = {
                    ...props.programContent,
                    beskrivelse: props.programContent.beskrivelse || "",
                    after: props.programContent.beskrivelseAfter || "",
                };               
                setProgramContent(parsedContent);
                setIsLoading(false);
            }
        });
    }, []);

    const onUpdateContent = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsLoading(true);
      
        if (!programContent) {
          setError("Ingen kursusdata fundet.");
          return;
        }
      
        try {
          const updatedProgramObj: IProgram = {
            ...programContent,
            beskrivelse: Array.isArray(programContent.beskrivelse)
              ? programContent.beskrivelse
                  .map(
                    (section) =>
                      `<div class="accordiondesc">${section.desc}<i class="arrowdesc"></i></div>` +
                      `<div class="accordionpanel">${section.panel}</div>`
                  )
                  .join("")
              : programContent.beskrivelse,
            beskrivelseCert: Array.isArray(programContent.beskrivelseCert)
              ? programContent.beskrivelseCert
                  .map(
                    (section) =>
                      `<div class="accordiondesc">${section.desc}<i class="arrowdesc"></i></div>` +
                      `<div class="accordionpanel">${section.panel}</div>`
                  )
                  .join("")
              : programContent.beskrivelseCert,
            beskrivelseTests: Array.isArray(programContent.beskrivelseTests)
              ? programContent.beskrivelseTests
                  .map(
                    (section) =>
                      `<div class="accordiondesc">${section.desc}<i class="arrowdesc"></i></div>` +
                      `<div class="accordionpanel">${section.panel}</div>`
                  )
                  .join("")
              : programContent.beskrivelseTests,
            beskrivelseSeminarer: Array.isArray(programContent.beskrivelseSeminarer)
              ? programContent.beskrivelseSeminarer
                  .map(
                    (section) =>
                      `<div class="accordiondesc">${section.desc}<i class="arrowdesc"></i></div>` +
                      `<div class="accordionpanel">${section.panel}</div>`
                  )
                  .join("")
              : programContent.beskrivelseSeminarer,
            beskrivelseAfter: Array.isArray(programContent.beskrivelseAfter)
              ? programContent.beskrivelseAfter
                  .map(
                    (section) =>
                      `<div class="accordiondesc">${section.desc}<i class="arrowdesc"></i></div>` +
                      `<div class="accordionpanel">${section.panel}</div>`
                  )
                  .join("")
              : programContent.beskrivelseAfter,
          };
      
          await updateProgramContent(token, updatedProgramObj);
          setProgramContent(updatedProgramObj);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setError("Fejl ved opdatering af indhold.");
        }
    };
      
      

    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  minHeight: 0,
                },
                scrollableContent: {
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 0,
                  overflowY: 'auto',
                  height: '100%'
                },
              }}
        >
            <div
                style={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
                backgroundColor: 'white',
                paddingBottom: '1rem ',
                marginTop: '-18px',
                paddingTop: '1rem',
                width: '90%',
                }}
            >
                <h4 style={{margin: 0}}>{props?.programContent.navn}</h4>
                <p>Administrer indhold</p>
            </div>
            {!isLoading && programContent !== null && (
                <>
                    <Pivot
                        styles={{
                            root: {
                            display: 'flex',
                            flex: 1,
                            minHeight: 0,
                            },
                            itemContainer: {
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            minHeight: 0,
                            },
                        }}
                    >
                        <PivotItem headerText="Program indhold" style={{height: '100%'}} alwaysRender>
                            <div className="container">
                                {programContent.beskrivelse !== "" && (
                                    <div className="contentLearnContainer">
                                        <h4>Kursus beskrivelse</h4>
                                        <ProgramContentEditor
                                            initialHtml={programContent.beskrivelse}
                                            onContentChange={(updatedHtml) =>
                                                setProgramContent((prev) => ({
                                                ...prev,
                                                beskrivelse: updatedHtml,
                                                }))
                                            }
                                        />
                                    </div>
                                )}


                                {programContent.beskrivelseAfter !== '' && (
                                    <div className="contentContainer">
                                        <h4>Beskrivelse efter</h4>
                                        <ProgramContentEditor
                                            initialHtml={programContent.beskrivelseAfter}
                                            onContentChange={(updatedHtml) =>
                                                setProgramContent((prev) => ({
                                                ...prev,
                                                beskrivelseAfter: updatedHtml,
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Certificering" style={{height: '100%'}} alwaysRender>
                                {programContent.beskrivelseCert !== '' && (
                                    <div className="contentRecieveContainer">
                                        <Label>Certificering beskrivelse</Label>
                                        <TextEditor
                                            initialContent={programContent.beskrivelseCert}
                                            onContentChange={(updatedSections) =>
                                                setProgramContent((prev) => ({
                                                    ...prev,
                                                    beskrivelseCert: updatedSections,
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                        </PivotItem>
                        <PivotItem headerText="Tests" style={{height: '100%'}} alwaysRender>
                                {programContent.beskrivelseTests !== '' && (
                                    <div className="contentShouldContainer">
                                        <Label>Tests beskrivelse</Label>
                                        <TextEditor
                                            initialContent={programContent.beskrivelseTests}
                                            onContentChange={(updatedContent) =>
                                                setProgramContent((prev) => ({
                                                    ...prev,
                                                    beskrivelseTests: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                        </PivotItem>
                        <PivotItem headerText="Seminarer" style={{height: '100%'}} alwaysRender>
                                {programContent.beskrivelseSeminarer !== '' && (
                                    <div className="contentContainer">
                                        <Label>Seminarer beskrivelse</Label>
                                        <TextEditor
                                        initialContent={programContent.beskrivelseSeminarer}
                                        onContentChange={(updatedContent) =>
                                            setProgramContent((prev) => ({
                                            ...prev,
                                            beskrivelseSeminarer: updatedContent,
                                            }))
                                        }
                                        />
                                    </div>
                                )}
                        </PivotItem>
                    </Pivot>
                    
                    <div className="btnSaveContainer">
                        <PrimaryButton className="btnSave" onClick={onUpdateContent}>
                            Gem indhold
                        </PrimaryButton>
                    </div>
                    {error != "" && (
                        <Alert severity="warning">{error}</Alert>
                    )}
                </>
            )}


        </Panel>
    )
}

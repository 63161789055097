import { Shimmer } from '@fluentui/react';
import classes from '../styles/Oversigt.module.css'
import { IOverviewItem } from './RegistrationsTable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { classNames } from 'primereact/utils';


export function numberWithCommas(num: number) {
    if(!num) return num;
    var x = num.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1.$2");
    return x;
}

export default function Oversigt(props: { yearOversigt: any }): JSX.Element {

    if (props.yearOversigt) {
        var overview: {} = {};

        const currentYear = new Date().getFullYear();


        for (let item in props.yearOversigt) {
            const company = item.split(' ')[0]
            if (!overview[props.yearOversigt[item].year]) {
                overview[props.yearOversigt[item].year] = [];
            }
            overview[props.yearOversigt[item].year].push({
                year: props.yearOversigt[item].year,
                value: props.yearOversigt[item].amount,
                company: company
            })

        }

        const sortedKeys = Object.keys(overview).sort((a, b) => parseInt(b) - parseInt(a));
 
        return (
            <div className={classes.oversigtContainer}>
                <div className={classes.title}>Årsoversigt</div>
                <div className="card">
                    <Accordion multiple activeIndex={0}>
                        {sortedKeys.map((item) => {
                            return (
                                <AccordionTab key={item} header={item} >
                                    {overview[item].sort((a, b) => a.company.localeCompare(b.company)).map((company, index) => {
                                        return (
                                            <div key={index} className={classes.priceContainer}>
                                                <div className={classes.priceContainerLabel}>{company.company}: </div>
                                                <div className={classes.priceContainerContent}>{numberWithCommas(company.value)},- kr</div>
                                            </div>
                                        );
                                    })}
                                    <div key={3} className={classes.priceContainer}>
                                        <div className={classes.priceContainerLabel}>Samlet resultat : </div>
                                        <div className={classes.priceContainerContent}>{numberWithCommas(overview[item][0].value + (overview[item][1] ? overview[item][1].value : 0))},- kr</div>
                                    </div>
                                </AccordionTab>
                            );
                        })}
                    </Accordion>
                </div>
            </div>


        )
    } else {
        return (
            <div>
                <Shimmer />
            </div>
        )
    }
}

import { Label, Panel, PanelType, PrimaryButton, DetailsList, DetailsListLayoutMode, SelectionMode, IconButton, Dialog, DialogFooter, DialogType, Spinner, SpinnerSize, DefaultButton } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { ICourseType, IProgram } from "./DataTable";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getCourseTypes, createCourseProgramRelation, deleteCourseProgramRelation, getProgramRelations } from "../api";
import "../styles/ContentPanel.css";

interface IProgramRelation {
    programnavnID: number;
    kursustypeID: number;
}

export default function ShownCoursesPanel(props: { program: IProgram, onDismiss: () => void; }): JSX.Element {
    const [program, setProgram] = useState<IProgram | null>(props.program);
    const [directCourseTypes, setDirectCourseTypes] = useState<ICourseType[]>([]);
    const [relatedCourseTypes, setRelatedCourseTypes] = useState<ICourseType[]>([]);
    const [availableCourses, setAvailableCourses] = useState<ICourseType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [selectedCourseToAdd, setSelectedCourseToAdd] = useState<ICourseType | null>(null);

    useEffect(() => {
        setIsLoading(true);
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
            fetchCourseTypesAndRelations(response.accessToken);
            setIsLoading(false);
        });
    }, [props]);

    const fetchCourseTypesAndRelations = async (accessToken: string) => {
        try {
            const [courseResponse, relationResponse] = await Promise.all([
                getCourseTypes(accessToken, 0),
                getProgramRelations(accessToken, program.programnavnID)
            ]);

            if (courseResponse?.data && relationResponse?.data) {
                const relations: IProgramRelation[] = relationResponse.data;
                const relatedCourseIds = relations.map(rel => rel.kursustypeID);
                const directCourses = courseResponse.data.filter((course: ICourseType) => course.programnavnID === program.programnavnID);
                const relatedCourses = courseResponse.data.filter((course: ICourseType) => relatedCourseIds.includes(course.kursustypeID) && course.programnavnID !== program.programnavnID);
                const availableCourses = courseResponse.data.filter((course: ICourseType) => !relatedCourseIds.includes(course.kursustypeID) && course.programnavnID !== program.programnavnID);

                setDirectCourseTypes(directCourses);
                setRelatedCourseTypes(relatedCourses);
                setAvailableCourses(availableCourses);
            } else {
                setError("Fejl ved hentning af kurser og relationer.");
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setError("Fejl ved hentning af kurser og relationer.");
            setIsLoading(false);
        }
    };

    const onAddCourse = async () => {
        if (!selectedCourseToAdd) {
            setError("Vælg et kursus at tilføje.");
            return;
        }

        setIsDialogLoading(true);
        try {
            await createCourseProgramRelation(token, selectedCourseToAdd.kursustypeID, program.programnavnID);
            fetchCourseTypesAndRelations(token);
            setSelectedCourseToAdd(null);
            setIsDialogVisible(false);
        } catch (error) {
            console.error(error);
            setError("Fejl ved tilføjelse af kursus.");
        } finally {
            setIsDialogLoading(false);
        }
    };

    const onRemoveCourse = async (courseTypeID: number) => {
        try {
            await deleteCourseProgramRelation(token, courseTypeID, program.programnavnID);
            fetchCourseTypesAndRelations(token);
        } catch (error) {
            console.error(error);
            setError("Fejl ved fjernelse af kursus.");
        }
    };

    const showAddCourseDialog = (course: ICourseType) => {
        setSelectedCourseToAdd(course);
        setIsDialogVisible(true);
    };

    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: 0,
                },
                scrollableContent: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0,
                    overflowY: 'auto',
                    height: '100%'
                },
            }}
        >
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    backgroundColor: 'white',
                    paddingBottom: '1rem ',
                    marginTop: '-18px',
                    paddingTop: '1rem',
                    width: '90%',
                }}
            >
                <h4 style={{ margin: 0 }}>{props?.program.navn}</h4>
                <p>Administrer kurser</p>
            </div>
            {!isLoading && program !== null && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <>
                        <Label>Kurser</Label>
                        <DetailsList
                            items={directCourseTypes}
                            columns={[
                                {
                                    key: 'kursusTitel',
                                    name: 'Direkte kurser',
                                    fieldName: 'direkteTitel',
                                    minWidth: 175,
                                    maxWidth: 300,
                                    onRender: (item: ICourseType) => (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>{item.kursusTitel}</span>
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                title="Fjern"
                                                ariaLabel="Fjern"
                                                onClick={() => onRemoveCourse(item.kursustypeID)}
                                            />
                                        </div>
                                    )
                                }
                            ]}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />
                    </>
                    <DetailsList
                        items={relatedCourseTypes}
                        columns={[
                            {
                                key: 'kursusTitel',
                                name: 'Relaterede kurser',
                                fieldName: 'relateredeTitel',
                                minWidth: 175,
                                maxWidth: 300,
                                onRender: (item: ICourseType) => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span>{item.kursusTitel}</span>
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            title="Fjern"
                                            ariaLabel="Fjern"
                                            onClick={() => onRemoveCourse(item.kursustypeID)}
                                        />
                                    </div>
                                )
                            }
                        ]}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        selectionPreservedOnEmptyClick={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        styles={{ root: { marginBottom: '2rem' } }}
                    />
                    <>
                        <Label>Tilføj kurser</Label>
                        <DetailsList
                            items={availableCourses}
                            columns={[
                                {
                                    key: 'kursusTitel',
                                    name: 'Titel',
                                    fieldName: 'titel',
                                    minWidth: 175,
                                    maxWidth: 300,
                                    onRender: (item: ICourseType) => (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>{item.kursusTitel}</span>
                                            <IconButton
                                                iconProps={{ iconName: 'Add' }}
                                                title="Tilføj"
                                                ariaLabel="Tilføj"
                                                onClick={() => showAddCourseDialog(item)}
                                            />
                                        </div>
                                    )
                                }
                            ]}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />
                    </>
                    {error && (
                        <Alert severity="warning">{error}</Alert>
                    )}
                </div>
            )}

            <Dialog
                hidden={!isDialogVisible}
                onDismiss={() => setIsDialogVisible(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Bekræft tilføjelse",
                    subText: `Er du sikker på, at du vil tilføje kurset "${selectedCourseToAdd?.kursusTitel}" til programmet?`,
                }}
                modalProps={{ isBlocking: true }}
            >
                {isDialogLoading ? (
                    <Spinner size={SpinnerSize.medium} />
                ) : (
                    <DialogFooter>
                        <PrimaryButton
                            text="Ja, tilføj"
                            onClick={onAddCourse}
                            disabled={isDialogLoading}
                        />
                        <DefaultButton text="Annuller" onClick={() => setIsDialogVisible(false)} />
                    </DialogFooter>
                )}
            </Dialog>
        </Panel>
    );
}

import { DefaultButton, IconButton, Label, Dropdown, IDropdownOption } from "@fluentui/react";
import { ICourseType, ILevel } from "./DataTable";
import '../styles/CourseActionList.css';
import RequireRole from "./RequireRole";
import { updateCourseContent } from "../api";
import { useState } from "react";

export default function CourseActionList(props: {
    courseTypes: ICourseType[],
    levels: ILevel[],
    token: string,
    onClickRelated: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickContent: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickDate: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickPrice: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickDuration: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickDelete: React.Dispatch<React.SetStateAction<ICourseType | null>>;
}) {
    const getNiveauNr = (niveauID: number) => {
        const level = props.levels.find(level => level.niveauID === niveauID);
        return level ? level.niveauNr : null;
    };

    const sortCourses = (a: ICourseType, b: ICourseType) => {
        const niveauNrA = getNiveauNr(a.niveauID);
        const niveauNrB = getNiveauNr(b.niveauID);

        if (niveauNrA !== null && niveauNrB !== null) {
            return niveauNrA - niveauNrB;
        } else if (niveauNrA === null && niveauNrB === null) {
            return a.kursusTitel.localeCompare(b.kursusTitel);
        } else if (niveauNrA === null) {
            return 1;
        } else {
            return -1;
        }
    };

    const activeCourses = props.courseTypes.filter(type => !type.inaktiv).sort(sortCourses);
    const inactiveCourses = props.courseTypes.filter(type => type.inaktiv).sort(sortCourses);

    const dropdownOptions = {
        levels: props.levels.map((t: { niveauID: number; navn: string; niveauNr: number; }) => ({ key: t.niveauID, text: t.niveauNr + ": " + t.navn })),
    }

    const [selectedKeys, setSelectedKeys] = useState<{ [key: number]: number | null }>({});

    const onDropdownChange = async (type: ICourseType, option?: IDropdownOption): Promise<void> => {
        const niveauID = option?.key ? parseInt(String(option.key), 10) : null;
        console.log(niveauID);
        const updatedTypeObj = {
            ...type,
            niveauID: niveauID,
        };
        await updateCourseContent(props.token, updatedTypeObj);
        setSelectedKeys({ ...selectedKeys, [type.kursustypeID]: niveauID });
    };

    return (
        <div className="courseActionContainer">
            <div className="activeCourses">
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginRight: "1rem" }}>
                    <Label>Aktive kurser</Label>
                    <Label>Sorteringsrækkefølge: Niveau</Label>
                </div>
                {activeCourses.map((type) => (
                    <div className='courseContainer' key={type.kursustypeID}>
                        <div className="courseTitle">{type.kursusTitel}</div>
                        <div className="btnContainer">
                            <RequireRole roles={["Admin"]}>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    title="Slet"
                                    ariaLabel="slet"
                                    onClick={() => props.onClickDelete(type)}
                                />
                            </RequireRole>
                            <RequireRole roles={["Admin", "Editor"]}>
                                <DefaultButton className="btnStyle" onClick={() => props.onClickRelated(type)}>Administrer relationer</DefaultButton>
                                <DefaultButton className="btnStyle" onClick={() => props.onClickContent(type)}>Administrer indhold</DefaultButton>
                            </RequireRole>
                            <DefaultButton className="btnStyle" onClick={() => props.onClickDate(type)}>Administrer datoer</DefaultButton>
                            <DefaultButton className="btnStyle" onClick={() => props.onClickPrice(type)}>Administrer priser</DefaultButton>
                            <DefaultButton className="btnStyle" onClick={() => props.onClickDuration(type)}>Administrer varighed</DefaultButton>
                            <RequireRole roles={["Admin", "Editor"]}>
                                <Dropdown
                                    style={{ width: '200px' }}
                                    placeholder="Vælg niveau"
                                    options={dropdownOptions.levels}
                                    onChange={(e, option) => onDropdownChange(type, option)}
                                    selectedKey={selectedKeys[type.kursustypeID] ?? type.niveauID}
                                />
                            </RequireRole>
                        </div>
                    </div >
                ))
                }
            </div >
            <div className="inactiveCourses">
                {inactiveCourses.length > 0 && <Label>Inaktive kurser</Label>}
                {inactiveCourses.map((type) => (
                    <div className='courseContainerInactive' key={type.kursustypeID}>
                        <div className="courseTitle">(Slettet) {type.kursusTitel}</div>
                        <div className="btnContainer">
                            <RequireRole roles={["Admin"]}>
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    title="Tilføj"
                                    ariaLabel="tilføj"
                                    onClick={() => props.onClickDelete(type)}
                                />
                            </RequireRole>
                            <RequireRole roles={["Admin", "Editor"]}>
                                <DefaultButton className="btnStyle" onClick={() => props.onClickContent(type)}>Administrer indhold</DefaultButton>
                                <DefaultButton className="btnStyle" onClick={() => props.onClickContent(type)}>Administrer indhold</DefaultButton>
                            </RequireRole>
                            <DefaultButton className="btnStyle" onClick={() => props.onClickDate(type)}>Administrer datoer</DefaultButton>
                            <DefaultButton className="btnStyle" onClick={() => props.onClickPrice(type)}>Administrer priser</DefaultButton>
                            <DefaultButton className="btnStyle" onClick={() => props.onClickDuration(type)}>Administrer varighed</DefaultButton>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
}
import { useState } from "react";
import TextEditor from "./TextEditor";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react";


const parseAccordionContent = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const children = Array.from(doc.body.childNodes);

  const preAccordion: string[] = [];
  const accordions: { desc: string; panel: string }[] = [];
  const postAccordion: string[] = [];

  let currentAccordion: { desc: string; panel: string } | null = null;
  let inAccordion = false;

  children.forEach((node) => {
    if (node instanceof HTMLElement) {
      if (node.classList.contains("accordiondesc")) {
        if (currentAccordion) {
          accordions.push(currentAccordion);
        }
        currentAccordion = { desc: node.innerHTML.trim(), panel: "" };
        inAccordion = true;
      } else if (node.classList.contains("accordionpanel")) {
        if (currentAccordion) {
          currentAccordion.panel = node.innerHTML.trim();
        }
      } else {
        if (!inAccordion) {
          preAccordion.push(node.outerHTML.trim());
        } else if (currentAccordion) {
          postAccordion.push(node.outerHTML.trim());
        }
      }
    } else if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
      if (!inAccordion) {
        preAccordion.push(node.textContent.trim());
      } else if (currentAccordion) {
        postAccordion.push(node.textContent.trim());
      }
    }
  });

  if (currentAccordion) {
    accordions.push(currentAccordion);
  }

  return {
    preAccordion: preAccordion.join("\n"),
    accordions,
    postAccordion: postAccordion.join("\n"),
  };
};

export default function ProgramContentEditor(props: { initialHtml: string; onContentChange: (html: string) => void }) {
  const { preAccordion, accordions, postAccordion } = parseAccordionContent(props.initialHtml || "");

  const [preContent, setPreContent] = useState(preAccordion);
  const [accordionContent, setAccordionContent] = useState(accordions) || [];
  const [postContent, setPostContent] = useState(postAccordion);

  const handleAccordionChange = (index: number, type: "desc" | "panel", value: string) => {
    const updatedAccordionContent = accordionContent.map((accordion, i) =>
      i === index
        ? {
          ...accordion,
          [type]: value,
        }
        : accordion
    );

    setAccordionContent(updatedAccordionContent);

    sendUpdatedContent(preContent, updatedAccordionContent, postContent);
  };

  const handleAddAccordion = () => {
    const newAccordion = { desc: "<p>Ny accordion titel</p>", panel: "<p>Ny accordion beskrivelse</p> <br>" };
    const updatedAccordionContent = [...accordionContent, newAccordion];
    setAccordionContent(updatedAccordionContent);
    sendUpdatedContent(preContent, updatedAccordionContent, postContent);
  };

  const handleDeleteAccordion = (index: number) => {
    const updatedAccordionContent = accordionContent.filter((_, i) => i !== index);
    setAccordionContent(updatedAccordionContent);
    sendUpdatedContent(preContent, updatedAccordionContent, postContent);
  };

  const handlePreContentChange = (value: string) => {
    setPreContent(value);
    sendUpdatedContent(value, accordionContent, postContent);
  };

  const handlePostContentChange = (value: string) => {
    setPostContent(value);
    sendUpdatedContent(preContent, accordionContent, value);
  };

  const handleAddPreContent = () => {
    setPreContent("Nyt indhold før accordion");
    sendUpdatedContent(preContent, accordionContent, postContent);
  };

  const handleAddPostContent = () => {
    setPostContent("Nyt indhold efter accordion");
    sendUpdatedContent(preContent, accordionContent, postContent);
  };

  const handleDeletePreContent = () => {
    setPreContent("");
    sendUpdatedContent("", accordionContent, postContent);
  };

  const handleDeletePostContent = () => {
    setPostContent("");
    sendUpdatedContent(preContent, accordionContent, "");
  };

  const sendUpdatedContent = (updatedPreContent: string | null, updatedAccordions: { desc: string; panel: string }[] | null, updatedPostContent: string | null) => {
    const finalHtml = [
      updatedPreContent ?? "",
      ...updatedAccordions.map((accordion) => {
        const descWithArrow = accordion.desc.includes('<i class="arrowdesc"></i>')
          ? accordion.desc
          : `${accordion.desc}<i class="arrowdesc"></i>`;
        return `<div class="accordiondesc">${descWithArrow}</div><div class="accordionpanel">${accordion.panel}</div>`;
      }),
      updatedPostContent ?? "",
    ].join("\n");
  
    props.onContentChange(finalHtml);
  };

  return (
    <div>
      {props.initialHtml ? (
        <>
          {preContent !== "" && (
            <>
              <h6>Indhold før accordion</h6>
              <TextEditor initialContent={preContent} onContentChange={handlePreContentChange} />
            </>
          )}
          {accordionContent.length > 0 && (
            <div className="accordionWrapper">
              <h6>Accordion</h6>
              {accordionContent.map((accordion, index) => (
                <div key={index} className="accordionContainer">
                  <label>Accordion titel:</label>
                  <TextEditor
                    initialContent={accordion.desc}
                    onContentChange={(value) => handleAccordionChange(index, "desc", value)}
                  />
                  <label>Accordion tekst:</label>
                  <TextEditor
                    initialContent={accordion.panel}
                    onContentChange={(value) => handleAccordionChange(index, "panel", value)}
                  />
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title='Slet'
                    ariaLabel='Slet'
                    onClick={() => handleDeleteAccordion(index)}
                  />
                  <span className="accordionNum">{index + 1}.</span>
                </div>
              ))}
            </div>
          )}
          {postContent !== "" && (
            <>
              <h6>Indhold efter accordion</h6>
              <TextEditor initialContent={postContent} onContentChange={handlePostContentChange} />
            </>
          )}
          <div className="accordionBtnContainer">
            {preContent === "" ? (
              <DefaultButton className="btnStyle" onClick={handleAddPreContent}>
                Tilføj indhold før accordion
              </DefaultButton>
            ) : (
              <DefaultButton className="btnStyle del" onClick={handleDeletePreContent}>
                Slet indhold før accordion
              </DefaultButton>
            )}
            <PrimaryButton className="btnSave" onClick={() => handleAddAccordion()}>
              Tilføj accordion punkt
            </PrimaryButton>
            {postContent === "" ? (
              <DefaultButton className="btnStyle" onClick={handleAddPostContent}>
                Tilføj indhold efter accordion
              </DefaultButton>
            ) : (
              <DefaultButton className="btnStyle del" onClick={handleDeletePostContent}>
                Slet indhold efter accordion
              </DefaultButton>
            )}
          </div>
        </>
      ) : (
          <div className="accordionBtnContainer">
            {preContent === "" ? (
              <DefaultButton className="btnStyle" onClick={handleAddPreContent}>
                Tilføj indhold før accordion
              </DefaultButton>
            ) : (
              <DefaultButton className="btnStyle del" onClick={handleDeletePreContent}>
                Slet indhold før accordion
              </DefaultButton>
            )}
            <PrimaryButton className="btnSave" onClick={() => handleAddAccordion()}>
              Tilføj accordion punkt
            </PrimaryButton>
            {postContent === "" ? (
              <DefaultButton className="btnStyle" onClick={handleAddPostContent}>
                Tilføj indhold efter accordion
              </DefaultButton>
            ) : (
              <DefaultButton className="btnStyle del" onClick={handleDeletePostContent}>
                Slet indhold efter accordion
              </DefaultButton>
            )}
          </div>
      )}
    </div>
  );
}


import { ITextField, Label, Panel, PanelType, PrimaryButton, TextField, Pivot, PivotItem } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { ICourseProgram, ICourseType } from "./DataTable";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getCourseTypes, updateCourseContent } from "../api";
import "../styles/ContentPanel.css";
import TextEditor from "./TextEditor";
import ProgramContentEditor from "./ProgramContentEditor";

export default function ContentPanel(props: { courseType: ICourseType, program: ICourseProgram, onDismiss: () => void; }): JSX.Element {
    const [courseTypes, setCourseTypes] = useState<ICourseType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const inputLearnContent = useRef<ITextField>(null);
    const inputRecieveContent = useRef<ITextField>(null);
    const inputShouldContent = useRef<ITextField>(null);
    const inputContent = useRef<ITextField>(null);
    const inputIntroContent = useRef<ITextField>(null);
    const inputPurposeContent = useRef<ITextField>(null);
    const inputMaterialContent = useRef<ITextField>(null);
    const inputParticipantsContent = useRef<ITextField>(null);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
        });
    }, [props])

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            if (props.program) {
                getCourseTypes(response.accessToken, props.program?.programnavnID).then((data: any) => {
                    const type = data.data.find((type: any) => type.kursusTitel === props.courseType.kursusTitel);

                    //Konverterer fra BBCode til Html
                    const updatedType = convertBBCodeToHTML(type);

                    //Tilføjer accordion logik til type.indhold
                    updatedType.indhold = addAccordionLogic(updatedType.indhold);

                    setCourseTypes(updatedType);
                    setIsLoading(false);
                })
            }
        });
    }, []);

    const normalizeLineBreaks = (input: string, lineBreak: string = '\n'): string => {
        return input.replace(/(\r\n|\n|\r)/g, lineBreak);
    };

    const prepareBbCode = (bbCode: string): string => {
        let modifiedCode = normalizeLineBreaks(bbCode);

        const lines = modifiedCode.split('\n');
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i].trim();

            if (line.startsWith('*')) {
                lines[i] = `[*]${line.substring(1)}[/*]`;

                if (i > 0 && lines[i - 1].startsWith('[*]')) {
                    lines[i - 1] += lines[i];
                    lines.splice(i, 1);
                    i--;
                }
            }
        }

        modifiedCode = lines.join('\n');

        modifiedCode = modifiedCode.replace(/\n?(\[\/?(list|quote|\*|u|o)\])\n?/g, '$1');

        modifiedCode = modifiedCode.replace(/\n/g, '[br]');

        return modifiedCode;
    };


    const bbCodeToHtmlParser = (bbCode: string): string => {
        if (!bbCode) return '';

        let html = prepareBbCode(bbCode);

        const tagMappings = {
            '\\[b\\](.*?)\\[/b\\]': '<strong>$1</strong>',
            '\\[i\\](.*?)\\[/i\\]': '<span style=\"font-style:italic;\">$1</span>',
            '\\[u\\](.*?)\\[/u\\]': '<p><strong>$1</strong></p>',
            '\\[quote\\](.*?)\\[/quote\\]': '<blockquote>$1</blockquote>',
            '\\[img\\](.*?)\\[/img\\]': '<img src="$1" />',
            '\\[link=(.*?)\\](.*?)\\[/link\\]': '<a href="$1">$2</a>',
            '\\[br\\]': '<br />',
            '\\[o\\](.*?)\\[/o\\]': '<h5>$1</h5>',
        };

        for (const [bbTag, htmlTag] of Object.entries(tagMappings)) {
            const regex = new RegExp(bbTag, 'gi');
            html = html.replace(regex, htmlTag);
        }

        html = html.replace(/\[list\]/gi, '<ul class="contentlist">');
        html = html.replace(/\[\/list\]/gi, '</ul>');

        html = html.replace(/\[\*\](.*?)\[\/\*\]/g, '<li>$1</li>');

        html = html.replace(/(?:<\/ul>\s*)?<li>(.*?)<\/li>(?!\s*<\/ul>)/gi, '<li>$1</li>');

        return html;
    };
      
    const addAccordionLogic = (html: string): string => {
        let indexH5 = html.indexOf('<h5>');
        let firstH5 = indexH5;
        let prefix = '';
      
        while (indexH5 >= 0) {
          html = html.replace('<h5>', `${prefix}<div class="accordiondesc">`);
          html = html.replace('</h5>', '<i class="arrowdesc"></i></div><div class="accordionpanel">');
          prefix = '</div>';
          indexH5 = html.indexOf('<h5>');
        }
      
        if (firstH5 >= 0) {
          html += prefix;
        }
      
        return html;
    };
      

    const convertBBCodeToHTML = (type: ICourseType) => {
        const containsBbCode = (type) => {
            const bbCodePattern = /\[(b|i|u|list|\*|quote|img|link|o)\]/i;

            return [
                type.indhold,
                type.duLaerer,
                type.duBoer,
                type.duFaar,
                type.indledning,
                type.kursusmål,
                type.kursusmateriale,
                type.deltagere,
            ].some((field) => field && bbCodePattern.test(field));
        };

        if (!containsBbCode(type)) return type;

        return {
            ...type,
            indhold: type.indhold ? bbCodeToHtmlParser(type.indhold) : type.indhold,
            duLaerer: type.duLaerer ? bbCodeToHtmlParser(type.duLaerer) : type.duLaerer,
            duBoer: type.duBoer ? bbCodeToHtmlParser(type.duBoer) : type.duBoer,
            duFaar: type.duFaar ? bbCodeToHtmlParser(type.duFaar) : type.duFaar,
            indledning: type.indledning ? bbCodeToHtmlParser(type.indledning) : type.indledning,
            kursusmål: type.kursusmål ? bbCodeToHtmlParser(type.kursusmål) : type.kursusmål,
            kursusmateriale: type.kursusmateriale ? bbCodeToHtmlParser(type.kursusmateriale) : type.kursusmateriale,
            deltagere: type.deltagere ? bbCodeToHtmlParser(type.deltagere) : type.deltagere,
        };
    };


    const onUpdateContent = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsLoading(true);
        if (!courseTypes) {
            setError('Ingen kursusdata fundet.');
            return;
        }

        try {
            let updatedTypeObj: ICourseType = {
                ...courseTypes,
            };

            const indholdVal = inputContent.current?.value ?? "";
            if (indholdVal !== "") {
                updatedTypeObj.indhold = indholdVal;
            }

            // updatedTypeObj.indhold = indholdVal;

            const laererVal = inputLearnContent.current?.value ?? "";
            if (laererVal !== "") {
                updatedTypeObj.duLaerer = laererVal;
            }

            const faarVal = inputRecieveContent.current?.value ?? "";
            if (faarVal !== "") {
                updatedTypeObj.duFaar = faarVal;
            }

            const boerVal = inputShouldContent.current?.value ?? "";
            if (boerVal !== "") {
                updatedTypeObj.duBoer = boerVal;
            }

            const introVal = inputIntroContent.current?.value ?? "";
            if (introVal !== "") {
                updatedTypeObj.indledning = introVal;
            }

            const purposeVal = inputPurposeContent.current?.value ?? "";
            if (purposeVal !== "") {
                updatedTypeObj.kursusmål = purposeVal;
            }

            const materialVal = inputMaterialContent.current?.value ?? "";
            if (materialVal !== "") {
                updatedTypeObj.kursusmateriale = materialVal;
            }

            const participantsVal = inputParticipantsContent.current?.value ?? "";
            if (participantsVal !== "") {
                updatedTypeObj.deltagere = participantsVal;
            }

            updatedTypeObj.kursusTitel = props.courseType.kursusTitel;
            updatedTypeObj.kursustypeID = props.courseType.kursustypeID;
            updatedTypeObj.programnavnID = props.program.programnavnID;
            updatedTypeObj.varighed = courseTypes.varighed;
            updatedTypeObj.pris = courseTypes.pris;

            await updateCourseContent(token, updatedTypeObj);
            setCourseTypes(updatedTypeObj);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: 0,
                },
                scrollableContent: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0,
                    overflowY: 'auto',
                    height: '100%'
                },
            }}
        >
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    backgroundColor: 'white',
                    paddingBottom: '1rem ',
                    marginTop: '-18px',
                    paddingTop: '1rem',
                    width: '90%',
                }}
            >
                <h4 style={{ margin: 0 }}>{props?.courseType.kursusTitel}</h4>
                <p>Administrer indhold</p>
            </div>
            {!isLoading && courseTypes !== null && (
                <>
                    <Pivot
                        styles={{
                            root: {
                                display: 'flex',
                                flex: 1,
                                minHeight: 0,
                            },
                            itemContainer: {
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                minHeight: 0,
                            },
                        }}
                    >
                        <PivotItem headerText="Kursus indhold" style={{ height: '100%' }} alwaysRender>
                            <div className="container">
                                {courseTypes.duLaerer !== '' && (
                                    <div className="contentLearnContainer">
                                        <Label>Det lærer du</Label>
                                        <TextEditor
                                            initialContent={courseTypes.duLaerer}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    duLaerer: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}

                                {courseTypes.duFaar !== '' && (
                                    <div className="contentRecieveContainer">
                                        <Label>Det får du</Label>
                                        <TextEditor
                                            initialContent={courseTypes.duFaar}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    duFaar: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}

                                {courseTypes.duBoer !== '' && (
                                    <div className="contentShouldContainer">
                                        <Label>Få det optimale ud af kurset</Label>
                                        <TextEditor
                                            initialContent={courseTypes.duBoer}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    duBoer: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}

                                {courseTypes.indhold !== '' && (
                                    <div className="contentContainer">
                                        <Label>Indhold</Label>
                                        <ProgramContentEditor
                                            initialHtml={courseTypes.indhold}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    indhold: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </PivotItem>

                        <PivotItem headerText="Diverse" style={{ height: '100%' }} alwaysRender>
                            <div className="container">
                                {courseTypes.duLaerer !== '' && (
                                    <div className="contentPurposeContainer">
                                        <Label>Kursusmål</Label>
                                        <TextEditor
                                            initialContent={courseTypes.kursusmål}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    kursusmål: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}

                                {courseTypes.duFaar !== '' && (
                                    <div className="contentParticipantsContainer">
                                        <Label>Deltagere</Label>
                                        <TextEditor
                                            initialContent={courseTypes.deltagere}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    deltagere: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}

                                {courseTypes.duBoer !== '' && (
                                    <div className="contentMaterialContainer">
                                        <Label>Kursusmateriale</Label>
                                        <TextEditor
                                            initialContent={courseTypes.kursusmateriale}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    kursusmateriale: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}

                                {courseTypes.indledning !== '' && (
                                    <div className="contentIndledningContainer">
                                        <Label>Indledning</Label>
                                        <TextEditor
                                            initialContent={courseTypes.indledning}
                                            onContentChange={(updatedContent) =>
                                                setCourseTypes((prev) => ({
                                                    ...prev,
                                                    indledning: updatedContent,
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </PivotItem>

                    </Pivot>

                    <div className="btnSaveContainer">
                        <PrimaryButton className="btnSave" onClick={onUpdateContent}>
                            Gem indhold
                        </PrimaryButton>
                    </div>
                    {error != "" && (
                        <Alert severity="warning">{error}</Alert>
                    )}
                </>
            )}


        </Panel>
    )
}

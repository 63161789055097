import { LogLevel } from "@azure/msal-browser";

const redirectUrl = window.location.href.indexOf("localhost") !== -1 ? "http://localhost:3000/" : window.location.href.indexOf("4dkurser") !== -1 ? "https://4dkurser.peoplenet.dk/" : "https://4dkursusinterface-bmbsdge4gqcbagc4.z01.azurefd.net";

export const msalConfig = {
    auth: {
        clientId: "6047cbe0-ebea-4862-b58c-ab20bf477886",
        authority: "https://login.microsoftonline.com/2a87cbf1-9133-479e-bd3a-fa4e7cd85065",
        redirectUri: redirectUrl,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: string, containsPii: boolean) => {	
                if (containsPii) {		
                    return;		
                }		

                switch (level) {		
                    case LogLevel.Error:	
                        console.error(message);			
                        return;		
                    case LogLevel.Info:		
                        console.info(message);	
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["api://6047cbe0-ebea-4862-b58c-ab20bf477886/user_impersonation"],
};

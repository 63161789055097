import React, { useEffect } from "react";
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import "./assets/theme.css"

// import custom theme from primereact and overwrite the default theme
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

import { PrimeReactProvider } from 'primereact/api';
import { PageLayout } from "./components/PageLayout";
import "./styles/App.css";
import { DataTable } from "./components/DataTable";
import { RegistrationTable } from "./components/RegistrationsTable";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { KurserFunction } from "./KurserContext";
import { ReportsView } from "./components/ReportsView";

export default function App() {

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [value, setValue] = React.useState('2');

    useEffect(() => {

        if (!isAuthenticated && !instance.getActiveAccount()) {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <KurserFunction>
            <AuthenticatedTemplate>
                <PrimeReactProvider>
                    <PageLayout accountName={accounts[0]?.name}>

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Kurser" value="1" />
                                <Tab label="Tilmeldinger" value="2" />
                                <Tab label="Rapporter" value="3" />
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <DataTable />
                        </TabPanel>
                        <TabPanel value="2">
                            <RegistrationTable />
                        </TabPanel>
                        <TabPanel value="3">
                            <ReportsView />
                        </TabPanel>
                    </TabContext>
                </Box>

                    </PageLayout>
                </PrimeReactProvider>
            </AuthenticatedTemplate>
        </KurserFunction>
    );


}

import { Label, Panel, PanelType, DetailsList, DetailsListLayoutMode, SelectionMode, IconButton, Dialog, DialogFooter, DialogType, Spinner, SpinnerSize, DefaultButton, TextField, PrimaryButton, Checkbox, MessageBar, MessageBarType } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { IProgram, IProducttype } from "./DataTable";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { createProducttype, getProducttypes, getPrograms, updateProducttype } from "../api";
import "../styles/ContentPanel.css";
import RequireRole from "./RequireRole";

export default function ProducttypePanel(props: { programs: IProgram[], onDismiss: (newProducttype: IProducttype, productProgram: IProgram, productProgramForSelect: IProgram) => void; }): JSX.Element {
    const [producttypes, setProducttypes] = useState<IProducttype[]>([]);
    const [groupedPrograms, setGroupedPrograms] = useState<{ [key: number]: IProgram[] }>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [selectedProducttype, setSelectedProducttype] = useState<IProducttype | null>(null);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
    const [isInactiveChange, setIsInactiveChange] = useState<boolean>(false);
    const [allPrograms, setAllPrograms] = useState<IProgram[]>([]);
    const [producttype, setProducttype] = useState<IProducttype>({
        produkttypeID: 0,
        navn: "",
        sort: 0,
        web: true,
        inaktiv: false
    });

    useEffect(() => {
        setIsLoading(true);
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
            fetchProducttypes(response.accessToken);
        });
    }, [props]);

    const fetchProducttypes = async (accessToken: string) => {
        try {
            const [producttypesResponse, programsResponse] = await Promise.all([
                getProducttypes(accessToken),
                getPrograms(accessToken, false, true)
            ]);

            if (producttypesResponse?.data && props.programs) {
                setProducttypes(producttypesResponse.data);
                const grouped = props.programs.reduce((acc: { [key: number]: IProgram[] }, program: IProgram) => {
                    if (!acc[program.produkttypeID]) {
                        acc[program.produkttypeID] = [];
                    }
                    acc[program.produkttypeID].push(program);
                    return acc;
                }, {});
                setGroupedPrograms(grouped);
            } else {
                setError("Fejl ved hentning af produkttyper og programmer.");
            }
            if (programsResponse && programsResponse?.data) {
                setAllPrograms(programsResponse.data);
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setError("Fejl ved hentning af produkttyper og programmer.");
            setIsLoading(false);
        }
    };

    const onUpdateProducttype = async () => {
        if (!selectedProducttype) {
            setError("Vælg en produkttype at opdatere.");
            return;
        }

        setIsDialogLoading(true);
        try {
            const updatedProducttype = {
                ...selectedProducttype,
                sort: isInactiveChange ? selectedProducttype.sort : sortOrder,
                inaktiv: isInactiveChange ? !selectedProducttype.inaktiv : selectedProducttype.inaktiv
            };
            await updateProducttype(token, updatedProducttype);
            fetchProducttypes(token);
            setSelectedProducttype(null);
            setIsDialogVisible(false);
        } catch (error) {
            console.error(error);
            setError("Fejl ved opdatering af produkttype.");
        } finally {
            setIsDialogLoading(false);
        }
    };

    const showUpdateProducttypeDialog = (producttype: IProducttype, isInactiveChange: boolean = false) => {
        setSelectedProducttype(producttype);
        setSortOrder(producttype.sort);
        setIsInactiveChange(isInactiveChange);
        setIsDialogVisible(true);
    };

    const onSortOrderChange = (producttypeID: number, newSortOrder: number) => {
        setProducttypes(prevProducttypes =>
            prevProducttypes.map(pt =>
                pt.produkttypeID === producttypeID ? { ...pt, sort: newSortOrder } : pt
            )
        );
    };

    const filteredProducttypes = producttypes
        .filter(pt => groupedPrograms[pt.produkttypeID] && groupedPrograms[pt.produkttypeID].length > 0)
        .sort((a, b) => a.sort - b.sort);


    const validateFields = () => {
        if (producttype.navn === "") {
            setWarningMessage("Vælg et navn.");
            return false;
        }
        if (producttype.sort == null || producttype.sort < 0) {
            setWarningMessage("Vælg en anden sorteringsværdi.");
            return false;
        }
        return true;
    };

    const onSaveProducttype = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setWarningMessage(null);
        setSuccessMessage(null);
        if (!validateFields()) return;

        setIsAddDialogVisible(true);
    };

    const confirmSaveProducttype = async () => {
        setIsDialogLoading(true);

        try {
            const newProducttype = await createProducttype(token, producttype);
            setSuccessMessage("Produkt type er blevet oprettet!");
            setProducttypes([...producttypes, producttype]);
            setIsDialogLoading(false);
            setIsAddDialogVisible(false);
            if (newProducttype && newProducttype.data) {
                props.onDismiss(newProducttype.data, null, null);
            }
        } catch (error) {
            console.error(error);
            setWarningMessage("Fejl ved oprettelse af kursus.");
        } finally {
            setIsDialogLoading(false);
        }
    };

    const handleEditProductProgram = (product: IProducttype) => {
        const productProgram = allPrograms.find((program) => program.produkttypeID === product.produkttypeID && program.navn === product.navn) || null;

        props.onDismiss(null, productProgram, null);
    }

    const handleSelectCourses = (product: IProducttype) => {
        const productProgram = allPrograms.find((program) => program.produkttypeID === product.produkttypeID && program.navn === product.navn) || null;

        props.onDismiss(null, null, productProgram);
    }

    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss(null, null, null)}
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: 0,
                },
                scrollableContent: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0,
                    overflowY: 'auto',
                    height: '100%'
                },
            }}
        >
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    backgroundColor: 'white',
                    paddingBottom: '1rem ',
                    marginTop: '-18px',
                    paddingTop: '1rem',
                    width: '90%',
                }}
            >
                <h4 style={{ margin: 0, marginBottom: "0.5rem" }}>Administrer Produkttyper</h4>
            </div>
            {!isLoading && (
                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 100 }}>
                    <h6>Tilføj produkt type</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            label="Produkt navn (bruges i url. eks. /kurser/[navn])"
                            styles={{ root: { width: '100%' } }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProducttype((prev) => ({ ...prev, navn: e.target.value }))}
                        />
                        <TextField
                            label="Sorteringsrækkefølge"
                            type="number"
                            styles={{ root: { width: '150px' } }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProducttype((prev) => ({ ...prev, sort: Number(e.target.value) }))}
                        />
                        <PrimaryButton text="Tilføj" styles={{ root: { alignSelf: 'flex-end' } }} onClick={onSaveProducttype} />
                    </div>
                    {warningMessage && (
                        <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setWarningMessage(null)}>
                            {warningMessage}
                        </MessageBar>
                    )}

                    {successMessage && (
                        <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessMessage(null)}>
                            {successMessage}
                        </MessageBar>
                    )}
                    <h6 style={{ marginTop: '2rem' }}>Nuværende produkt typer</h6>
                    {filteredProducttypes.map((producttype) => (
                        <div key={producttype.produkttypeID} style={{ marginBottom: '2rem' }}>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Label>{producttype.navn}</Label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", gap: "1rem" }}>
                                    <DefaultButton className="btnStyle" onClick={() => { handleSelectCourses(producttype) }}>Vælg kurser</DefaultButton>
                                    <DefaultButton className="btnStyle" onClick={() => { handleEditProductProgram(producttype) }}>Administrer indhold</DefaultButton>
                                    <TextField
                                        label="Sorteringsrækkefølge"
                                        type="number"
                                        value={producttype.sort.toString()}
                                        onChange={(_, newValue) => onSortOrderChange(producttype.produkttypeID, Number(newValue))}
                                        styles={{ root: { width: '150px' } }}
                                    />
                                    <IconButton
                                        iconProps={{ iconName: 'Checkmark' }}
                                        title='Opdater'
                                        ariaLabel='Opdater'
                                        onClick={() => showUpdateProducttypeDialog(producttype)}
                                    />
                                    <RequireRole roles={["Admin"]}>
                                        <IconButton
                                            iconProps={{ iconName: producttype.inaktiv ? 'Add' : 'Delete' }}
                                            title={producttype.inaktiv ? 'Aktiver' : 'Deaktiver'}
                                            ariaLabel={producttype.inaktiv ? 'aktiver' : 'deaktiver'}
                                            onClick={() => showUpdateProducttypeDialog(producttype, true)}
                                        />
                                    </RequireRole>
                                </div>
                            </div>
                            <DetailsList
                                items={groupedPrograms[producttype.produkttypeID] || []}
                                columns={[
                                    {
                                        key: 'programnavn',
                                        name: undefined,
                                        fieldName: 'navn',
                                        minWidth: 175,
                                        maxWidth: 300,
                                        isRowHeader: false,
                                        isMultiline: false,
                                        onRender: (item: IProgram) => (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>{item.navn}</span>
                                            </div>
                                        ),
                                    }
                                ]}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.none}
                                selectionPreservedOnEmptyClick={true}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="select row"
                                onRenderDetailsHeader={() => null}
                            />
                        </div>
                    ))}
                    {error && (
                        <Alert severity="warning">{error}</Alert>
                    )}
                </div>
            )}

            <Dialog
                hidden={!isDialogVisible}
                onDismiss={() => setIsDialogVisible(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: isInactiveChange ? "Bekræft handling" : "Bekræft opdatering",
                    subText: isInactiveChange
                        ? `Er du sikker på, at du vil ${selectedProducttype?.inaktiv ? 'aktivere' : 'deaktivere'} produkttypen "${selectedProducttype?.navn}"?`
                        : `Er du sikker på, at du vil opdatere produkttypen "${selectedProducttype?.navn}"?`,
                }}
                modalProps={{ isBlocking: true }}
            >
                {isDialogLoading ? (
                    <Spinner size={SpinnerSize.medium} />
                ) : (
                    <DialogFooter>
                        <PrimaryButton
                            text={isInactiveChange ? "Ja, bekræft" : "Ja, opdater"}
                            onClick={onUpdateProducttype}
                            disabled={isDialogLoading}
                        />
                        <DefaultButton text="Annuller" onClick={() => setIsDialogVisible(false)} />
                    </DialogFooter>
                )}
            </Dialog>
            <Dialog
                hidden={!isAddDialogVisible}
                onDismiss={() => setIsAddDialogVisible(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Bekræft handling",
                    subText: "Er du sikker på, at du vil tilføje denne produkt type?",
                }}
                modalProps={{ isBlocking: true }}
            >
                {isDialogLoading ? (
                    <Spinner size={SpinnerSize.medium} />
                ) : (
                    <DialogFooter>
                        <PrimaryButton
                            text="Ja, tilføj" onClick={confirmSaveProducttype} disabled={isDialogLoading || successMessage !== null}
                            styles={{
                                root: { backgroundColor: isDialogLoading || successMessage ? "#f3f3f3" : undefined }
                            }} />
                        {isDialogLoading ? (
                            <DefaultButton text="Annuller" onClick={() => setIsAddDialogVisible(false)} />
                        ) : (
                            <DefaultButton text="Luk" onClick={() => setIsAddDialogVisible(false)} />
                        )}
                    </DialogFooter>
                )}
                {successMessage && (
                    <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessMessage(null)}>
                        {successMessage}
                    </MessageBar>
                )}
            </Dialog>
        </Panel>
    );
}

import { Panel, PanelType, PrimaryButton, Dropdown, IDropdownOption, Checkbox, TextField, Pivot, PivotItem, Label, MessageBar, MessageBarType, Dialog, DialogType, DialogFooter, DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import Alert from "@mui/material/Alert";
import { IProgram, ICourseType, ILevel } from "./DataTable";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {
    createNewCourseType,
    getLanguages,
    getCourseTemplates,
    addDatasheetLayout,
} from "../api";
import "../styles/ContentPanel.css";
import ProgramContentEditor from "./ProgramContentEditor";
import TextEditor from "./TextEditor";

export default function NewCourseTypePanel(props: { levels: ILevel[]; programContent: IProgram; onDismiss: () => void; }): JSX.Element {
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [isDialogLoading, setIsDialogLoading] = useState(false);
    const [courseType, setCourseType] = useState<ICourseType>({
        kursustypeID: 0,
        programnavnID: props.programContent.programnavnID,
        sprogID: 80129, //dansk
        afholdelsesformID: 80080, //kursus
        kursuskategoriID: 1321029, //diverse
        databladSkabelonID: 264531, //standard
        niveauID: 0,
        kursusTitel: "",
        pris: 0,
        varighed: 0,
        som_firma_kursus: true,
        som_åbent_kursus: true,
        som_specialkursus: false,
        firmaJerPris: 0,
        firmaOsPris: 0,
        ejRabat: false,
        ejKlippekort: false,
        duLaerer: `På dette ${props.programContent.navn} kursus...`,
        duFaar: "Før kurset... På kurset... Efter kurset...",
        duBoer: `Dette ${props.programContent.navn} kursus...`,
        indhold: null,
        kursusmål: "",
        deltagere: "",
        kursusmateriale: "",
        indledning: "",
        inaktiv: false,
        spørgeskemaID: 129857,
    });
    const [dropdownOptions, setDropdownOptions] = useState<{
        programnavnID: IDropdownOption[];
        sprogID: IDropdownOption[];
        databladSkabelonID: IDropdownOption[];
        niveauID?: IDropdownOption[];
    }>({
        programnavnID: [],
        sprogID: [],
        databladSkabelonID: [],
        niveauID: [],
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error] = useState<string>("");
    const [isFirmakursusChecked, setIsFirmakursusChecked] = useState<boolean>(true);

    useEffect(() => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then(async (response) => {
                setToken(response.accessToken);
                try {
                    const [languages, templates]: any = await Promise.all([
                        getLanguages(response.accessToken),
                        getCourseTemplates(response.accessToken),
                    ]);
                    setDropdownOptions({
                        programnavnID: [{ key: props.programContent.programnavnID, text: props.programContent.navn }],
                        sprogID: languages.data.map((l: { sprogID: number; navn: string }) => ({ key: l.sprogID, text: l.navn })),
                        databladSkabelonID: templates.data.map((t: { databladSkabelonID: number; navn: string }) => ({ key: t.databladSkabelonID, text: t.navn })),
                        niveauID: props.levels.map((t: { niveauID: number; navn: string; niveauNr: number; }) => ({ key: t.niveauID, text: t.niveauNr + ": " + t.navn })),
                    });
                } catch (err) {
                    console.error("Kan ikke fetche dropdown options", err);
                } finally {
                    setIsLoading(false);
                }
            });
    }, [accounts, instance]);

    const onDropdownChange = (key: string, option?: IDropdownOption): void => {
        setCourseType((prev) => ({
            ...prev,
            [key]: option?.key,
        }));
    };

    const validateFields = () => {
        if (!courseType.sprogID) {
            setWarningMessage("Vælg et sprog.");
            return false;
        }
        if (!courseType.databladSkabelonID) {
            setWarningMessage("Vælg en datablad skabelon.");
            return false;
        }
        if (!courseType.kursusTitel || courseType.kursusTitel.trim() === "") {
            setWarningMessage("Kursus titel er påkrævet.");
            return false;
        }
        if (!courseType.pris || courseType.pris <= 0) {
            setWarningMessage("Pris skal være et positivt tal.");
            return false;
        }
        if (!courseType.varighed || courseType.varighed <= 0 || courseType.varighed >= 8) {
            setWarningMessage("Varighed skal være et positivt tal og under 8");
            return false;
        }
        if (isFirmakursusChecked && (!courseType.firmaOsPris || courseType.firmaOsPris <= 0)) {
            setWarningMessage("Firmakursus - hos os skal være et positivt tal.");
            return false;
        }
        if (isFirmakursusChecked && (!courseType.firmaJerPris || courseType.firmaJerPris <= 0)) {
            setWarningMessage("Firmakursus - hos dem skal være et positivt tal.");
            return false;
        }
        if (!courseType.duLaerer || courseType.duLaerer.trim() === "") {
            setWarningMessage("Det lærer du er påkrævet.");
            return false;
        }
        if (!courseType.duFaar || courseType.duFaar.trim() === "") {
            setWarningMessage("Det får du er påkrævet.");
            return false;
        }
        if (!courseType.duBoer || courseType.duBoer.trim() === "") {
            setWarningMessage("Få det optimale ud af kurset er påkrævet.");
            return false;
        }
        if (!courseType.indhold || courseType.indhold.trim() === "") {
            setWarningMessage("Indhold er påkrævet.");
            return false;
        }
        return true;
    };

    const onSaveCourse = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setWarningMessage(null);
        setSuccessMessage(null);
        if (!validateFields()) return;

        setIsDialogVisible(true);
    };

    const confirmSaveCourse = async () => {
        setIsDialogLoading(true);

        try {
            const newCourse: ICourseType = {
                ...courseType,
                programnavnID: props.programContent?.programnavnID
            };
            const courseResult = await createNewCourseType(token, newCourse);

            if (courseResult && courseResult.data) {
                await addDatasheetLayout(token, courseResult.data.kursustypeID);
            }
            setSuccessMessage("Kursus er blevet oprettet!");
            setIsDialogLoading(false);
            setCourseType({
                kursustypeID: 0,
                programnavnID: props.programContent.programnavnID,
                sprogID: 80129,
                afholdelsesformID: 80080,
                kursuskategoriID: 1321029,
                databladSkabelonID: 264531,
                niveauID: 0,
                kursusTitel: "",
                pris: 0,
                varighed: 0,
                som_firma_kursus: true,
                som_åbent_kursus: true,
                som_specialkursus: false,
                firmaJerPris: 0,
                firmaOsPris: 0,
                ejRabat: false,
                ejKlippekort: false,
                duLaerer: `På dette ${props.programContent.navn} kursus...`,
                duFaar: "Før kurset... På kurset... Efter kurset...",
                duBoer: `Dette ${props.programContent.navn} kursus...`,
                indhold: null,
                kursusmål: "",
                deltagere: "",
                kursusmateriale: "",
                indledning: "",
                inaktiv: false,
                spørgeskemaID: 129857,
            });

            setIsDialogVisible(false);
        } catch (error) {
            console.error(error);
            setWarningMessage("Fejl ved oprettelse af kursus.");
        } finally {
            setIsDialogLoading(false);
        }
    };

    const handleFirmakursusChange = (_e: React.FormEvent<HTMLElement>, checked?: boolean) => {
        setIsFirmakursusChecked(checked ?? false);
        if (!checked) {
            setCourseType((prev) => ({
                ...prev,
                firmaOsPris: 0,
                firmaJerPris: 0,
            }));
        }
    };

    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    minHeight: 0,
                },
                scrollableContent: {
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 0,
                    overflowY: "auto",
                    height: "100%",
                },
            }}
        >
            <div
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 100,
                    backgroundColor: "white",
                    paddingBottom: "1rem",
                    marginTop: "-18px",
                    paddingTop: "1rem",
                    width: "90%",
                }}
            >
                <h4 style={{ margin: 0 }}>Tilføj nyt kursus</h4>
            </div>
            {!isLoading && props.programContent && (
                <>
                    <Pivot>
                        <PivotItem headerText="Kursus" style={{ height: '100%' }} alwaysRender>
                            <div>
                                <Dropdown
                                    className="dropdown"
                                    label="Programnavn"
                                    options={dropdownOptions.programnavnID}
                                    disabled
                                    defaultSelectedKey={props.programContent.programnavnID}
                                />
                                <Dropdown className="dropdown" label="Afholdelsesform" options={[{ key: 80080, text: "Kursus" }]} disabled defaultSelectedKey={80080} />
                                <Dropdown
                                    className="dropdown"
                                    label="Sprog"
                                    placeholder="Vælg sprog"
                                    options={dropdownOptions.sprogID}
                                    onChange={(e, option) => onDropdownChange("sprogID", option)}
                                    selectedKey={80129}
                                    required
                                />
                                <Dropdown
                                    className="dropdown"
                                    label="Niveau (sorteringsrækkefølge)"
                                    placeholder="Vælg niveau"
                                    options={dropdownOptions.niveauID}
                                    onChange={(e, option) => onDropdownChange("niveauID", option)}
                                    required
                                />
                                <Dropdown
                                    className="dropdown"
                                    label="Datablad skabelon"
                                    placeholder="Vælg datablad skabelon"
                                    options={dropdownOptions.databladSkabelonID}
                                    onChange={(e, option) => onDropdownChange("databladSkabelonID", option)}
                                    selectedKey={264531}
                                    required
                                    disabled
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Kursus titel"
                                    required
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseType((prev) => ({ ...prev, kursusTitel: e.target.value }))}
                                    className="courseTextField"
                                    value={courseType.kursusTitel}
                                />
                                <div className="priceDurationContainer">
                                    <TextField
                                        label="Pris"
                                        type="number"
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseType((prev) => ({ ...prev, pris: Number(e.target.value) }))}
                                        className="courseTextField"
                                        value={courseType.pris.toString()}
                                    />
                                    <TextField
                                        label="Kursus varighed"
                                        type="number"
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseType((prev) => ({ ...prev, varighed: Number(e.target.value) }))}
                                        className="courseTextField"
                                        value={courseType.varighed.toString()}
                                    />
                                </div>
                                <div className="priceDurationContainer" style={{ marginBottom: '2rem' }}>
                                    {isFirmakursusChecked && (
                                        <>
                                            <TextField
                                                label="Firmakursus - hos os"
                                                type="number"
                                                required
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseType((prev) => ({ ...prev, firmaOsPris: Number(e.target.value) }))}
                                                className="courseTextField"
                                                value={courseType.firmaOsPris.toString()}
                                            />
                                            <TextField
                                                label="Firmakursus - hos dem"
                                                type="number"
                                                required
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCourseType((prev) => ({ ...prev, firmaJerPris: Number(e.target.value) }))}
                                                className="courseTextField"
                                                value={courseType.firmaJerPris.toString()}
                                            />
                                        </>
                                    )}
                                </div>
                                <Checkbox
                                    label="Tilbydes som firmakursus"
                                    onChange={handleFirmakursusChange}
                                    required
                                    defaultChecked
                                    className="courseCheckbox"
                                />
                                <Checkbox
                                    label="Tilbydes som åbent kursus"
                                    onChange={(e, checked) => setCourseType((prev) => ({ ...prev, som_åbent_kursus: checked }))}
                                    required
                                    defaultChecked
                                    className="courseCheckbox"
                                />
                                <Checkbox
                                    label="Rabat gælder ikke"
                                    onChange={(e, checked) => setCourseType((prev) => ({ ...prev, ejRabat: checked }))}
                                    required
                                    className="courseCheckbox"
                                />
                                <Checkbox
                                    label="Klippekort gælder ikke"
                                    onChange={(e, checked) => setCourseType((prev) => ({ ...prev, ejKlippekort: checked }))}
                                    required
                                    className="courseCheckbox"
                                />
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Indhold" style={{ height: '100%', marginBottom: '200px' }} alwaysRender>
                            <div>
                                <Label required>Det lærer du</Label>
                                <TextEditor
                                    initialContent={courseType.duLaerer}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            duLaerer: updatedContent,
                                        }))
                                    }
                                />
                                <Label required>Det får du</Label>
                                <TextEditor
                                    initialContent={courseType.duFaar}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            duFaar: updatedContent,
                                        }))
                                    }
                                />
                                <Label required>Få det optimale ud af kurset</Label>
                                <TextEditor
                                    initialContent={courseType.duBoer}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            duBoer: updatedContent,
                                        }))
                                    }
                                />
                                <Label required>Indhold</Label>
                                <ProgramContentEditor
                                    initialHtml={courseType.indhold}
                                    onContentChange={(updatedHtml) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            indhold: updatedHtml,
                                        }))
                                    }
                                />
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Diverse" style={{ height: '100%', marginBottom: '150px' }} alwaysRender>
                            <div>
                                <Label>Kursusmål</Label>
                                <TextEditor
                                    initialContent={courseType.kursusmål}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            kursusmål: updatedContent,
                                        }))
                                    }
                                />
                                <Label>Deltagere</Label>
                                <TextEditor
                                    initialContent={courseType.deltagere}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            deltagere: updatedContent,
                                        }))
                                    }
                                />
                                <Label>Kursusmateriale</Label>
                                <TextEditor
                                    initialContent={courseType.kursusmateriale}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            kursusmateriale: updatedContent,
                                        }))
                                    }
                                />
                                <Label>Indledning</Label>
                                <TextEditor
                                    initialContent={courseType.indledning}
                                    onContentChange={(updatedContent) =>
                                        setCourseType((prev) => ({
                                            ...prev,
                                            indledning: updatedContent,
                                        }))
                                    }
                                />
                            </div>
                        </PivotItem>
                    </Pivot>

                    <div className="btnSaveContainer">
                        {warningMessage && (
                            <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setWarningMessage(null)}>
                                {warningMessage}
                            </MessageBar>
                        )}

                        {successMessage && (
                            <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessMessage(null)}>
                                {successMessage}
                            </MessageBar>
                        )}

                        <Dialog
                            hidden={!isDialogVisible}
                            onDismiss={() => setIsDialogVisible(false)}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: "Bekræft handling",
                                subText: "Er du sikker på, at du vil tilføje dette kursus?",
                            }}
                            modalProps={{ isBlocking: true }}
                        >
                            {isDialogLoading ? (
                                <Spinner size={SpinnerSize.medium} />
                            ) : (
                                <DialogFooter>
                                    <PrimaryButton
                                        text="Ja, tilføj" onClick={confirmSaveCourse} disabled={isDialogLoading || successMessage !== null}
                                        styles={{
                                            root: { backgroundColor: isDialogLoading || successMessage ? "#f3f3f3" : undefined }
                                        }} />
                                    {isDialogLoading ? (
                                        <DefaultButton text="Annuller" onClick={() => setIsDialogVisible(false)} />
                                    ) : (
                                        <DefaultButton text="Luk" onClick={() => setIsDialogVisible(false)} />
                                    )}
                                </DialogFooter>
                            )}
                            {successMessage && (
                                <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessMessage(null)}>
                                    {successMessage}
                                </MessageBar>
                            )}
                        </Dialog>

                        {!successMessage ? (
                            <PrimaryButton text="Tilføj kursus" className="btnSave" onClick={onSaveCourse} />
                        ) : (
                            <PrimaryButton
                                text="Luk"
                                className="btnSave"
                                onClick={() => props.onDismiss()}
                                styles={{ root: { backgroundColor: "#f3f3f3", color: "#000" } }}
                            />
                        )}
                    </div>
                    {error && <Alert severity="warning">{error}</Alert>}
                </>
            )}
        </Panel>
    );
}

import { Box, Button, Checkbox, ListItemText, MenuItem, OutlinedInput, Select, Skeleton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
    DatePicker,
    DayOfWeek,
    Label,
    TextField
} from '@fluentui/react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import classes from "../styles/ReportView.module.css"
import { getPeoplenetPeriodCourseRegistration, getPeriodCourseRegistrations } from "../api";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { create4DRegistrationObject, createPeoplenetRegistrationObject, danishMonths, exportToExcel, ICourseRegistration } from "../utilities/utils";
import moment from "moment";
import { numberWithCommas } from "./Oversigt";

const databaseToObjectDataMap = {
    "Deltagere": "participants",
    "Kontaktpeson": "contactPerson",
    "Kontaktpeson email": "contactPersonEmail",
    "Kursus Id": "courseId",
    "Oplyst pris": "price",
    "Samlet pris": "totalPrice",
    "CVR nr.": "cvr",
    "Telefon": "phonenumber",
    "Har hørt om...": "heardFrom",
    "EAN": " ean",
    "Firmanavn": "firmName",
    "Kommentar": "comment"
}

export function ReportsView() {
    const { instance, accounts } = useMsal();
    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setFullYear(new Date().getFullYear() - 2)))
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [selectedColumns, setSelectedColumns] = useState<any>([]);
    const [selectedFirm, setSelectedFirm] = useState<string>("4D");
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [generatingReport, setGeneratingReport] = useState<boolean>(false);
    const [registrations, setRegistrations] = useState<ICourseRegistration[]>(null);
    const [filteredRegistrations, setFilteredRegistrations] = useState<ICourseRegistration[]>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [reportData, setReportData] = useState<any>(null);
    const searchInput = useRef<string>("");
    const firms = [
        "4D",
        "PeopleNet"
    ];
    const columns = [
        "Deltagere",
        "Kontaktpeson",
        "Kontaktpeson email",
        "Kursus Id",
        "Oplyst pris",
        "Samlet pris",
        "CVR nr.",
        "Telefon",
        "Har hørt om...",
        "EAN",
        "Firmanavn",
        "Kommentar"
    ];

    useEffect(() => {
        console.log(filteredRegistrations);
        if (!accessToken) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then(response => {
                if (response && response?.accessToken) {
                    setAccessToken(response?.accessToken);
                }
            })
        }

        if (filteredRegistrations) {
            generateReport();
        }
    }, [filteredRegistrations])

    const handleGetReportData = async () => {
        try {
            setGeneratingReport(true);
            const periodParam = {
                startDate: new Date(startDate.setHours(0, 0, 0, 0)).toISOString(),
                endDate: new Date(endDate.setHours(23, 59, 59, 999)).toISOString() // Include entire day
            };

            if (selectedFirm == "4D") {
                getPeriodCourseRegistrations(accessToken, periodParam)
                    .then((response: any) => {
                        if (response?.data) {
                            const data = create4DRegistrationObject(response.data);
                            const sortedData = data.sort((a, b) => b.registrationDate.getTime() - a.registrationDate.getTime());
                            setRegistrations(sortedData);
                            setFilteredRegistrations(sortedData);
                        }

                    })
                    .catch(e => {

                        console.log(e)
                    })
            } else if (selectedFirm == "PeopleNet") {

                getPeoplenetPeriodCourseRegistration(periodParam)
                    .then((response: any) => {

                        if (response?.data) {
                            const data = createPeoplenetRegistrationObject(response.data);
                            const sortedData = data.sort((a, b) => b.registrationDate.getTime() - a.registrationDate.getTime());

                            setRegistrations(sortedData);
                            setFilteredRegistrations(sortedData)
                        }

                    })
                    .catch(e => {

                        console.log(e)
                    })
            }

        } catch (e) {
            console.log(e)
        } finally {
            setDataLoaded(true);
            setGeneratingReport(false);
        }

    }

    const handleExportToExcel = async () => {

        if (filteredRegistrations) {
            var exportData = filteredRegistrations.map((item: ICourseRegistration) => {
                let filteredRegistration: any = {};
                filteredRegistration.Title = item.title;
                filteredRegistration["Registration Date"] = item.registrationDate;
                filteredRegistration["Course Start Date"] = item.startDate;
                if (selectedColumns) {
                    selectedColumns.forEach(column => {
                        filteredRegistration[databaseToObjectDataMap[column]] = item[databaseToObjectDataMap[column]];
                    })
                }

                return filteredRegistration;
            })

            var report = [];
            if (reportData) {
                report = Object.keys(reportData).map((key) => {
                    return {
                        ["Måned"]: danishMonths[key],
                        Total: reportData[key].totalPrice,
                        Tilmeldiger: reportData[key].totalAmount
                    }
                })
            }

            exportToExcel(exportData, `Tilmeldinger fra d. ${startDate.getDay()} ${danishMonths[startDate.getMonth()]} ${startDate.getFullYear()} til d. ${endDate.getDay()} ${danishMonths[endDate.getMonth()]} ${endDate.getFullYear()}`);
            exportToExcel(report, `Rapport data fra d.  ${startDate.getDay()} ${danishMonths[startDate.getMonth()]} ${startDate.getFullYear()} til d. ${endDate.getDay()} ${danishMonths[endDate.getMonth()]} ${endDate.getFullYear()}`);
        }

    }

    const handleSeach = (e: any) => {
        searchInput.current = e.target?.value;
        filterData();
    }

    const filterData = () => {

        try {
            if (!registrations) return; // Ensure registrations is not null

            let filteredData = registrations.filter((item: ICourseRegistration) =>
                item.title?.toLowerCase().includes(searchInput?.current?.toLowerCase())
            );

            setFilteredRegistrations([...filteredData]); // Ensure it's an array
        } catch (e) {
            console.error("Error filtering: ", e);
        }
    };

    const generateReport = () => {
        let uniqueMonths: any = {};

        if (filteredRegistrations) {
            filteredRegistrations.map((item) => {
                if (!uniqueMonths[item.month]) {
                    uniqueMonths[item.month] = {
                        totalPrice: 0,
                        totalAmount: 0,
                    };
                }
                const sumToAdd = selectedFirm == "4D" ? item.totalPrice : item.price;
                 
                if(sumToAdd)uniqueMonths[item.month].totalPrice += (typeof (sumToAdd) == "string" ? parseInt(sumToAdd) : sumToAdd);
                uniqueMonths[item.month].totalAmount++;
            })

            setReportData(uniqueMonths);
        }

    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const iconProps = { iconName: 'Search' };


    return (
        <div>
            <div className={classes.filters}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", marginBottom: "4px" }}>
                    <TextField
                        value={searchInput.current}
                        iconProps={iconProps}
                        onChange={(e) => handleSeach(e)}
                        disabled={!dataLoaded}
                        label="Søg"
                    />
                </div>

                <div className="firmPicker">
                    <Label>Vælg firma</Label>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        value={selectedFirm}
                        onChange={(e) => setSelectedFirm(e.target.value)}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => {
                            return (
                                <MenuItem key={selected} value={selected}>
                                    {selected}
                                </MenuItem>
                            )
                        }}
                        className={classes.filter}
                    >
                        {firms.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="columnPicker">
                    <Label>Vælg data for din rapport</Label>
                    <Select
                        labelId="Kolonner"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedColumns}
                        onChange={(e) => setSelectedColumns(e?.target?.value)}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => selected.join(', ')}
                        className={classes.filter}
                    >
                        {columns.map((name) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={selectedColumns.includes(name)} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    value={startDate}
                    placeholder="Vælg startdato"
                    label="Vælg startdato"
                    ariaLabel="Select a date"
                    onSelectDate={(e) => setStartDate(e)}
                    minDate={new Date("01/01/2016")}
                    maxDate={new Date()}
                    className={classes.filter}

                />
                <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    value={endDate}
                    placeholder="Vælg slutdato"
                    ariaLabel="Select a date"
                    label="Vælg slutdato"
                    onSelectDate={(e) => setEndDate(e)}
                    minDate={new Date("01/01/2016")}
                    maxDate={new Date()}
                    className={classes.filter}

                />
                <div style={{ display: "flex", alignItems: "end", flexDirection: "row", marginBottom: "6px" }}>
                    <Button
                        variant="contained"
                        endIcon={<AssignmentIcon />}
                        onClick={handleGetReportData}
                        className={classes.submitBtn}
                    >
                        Generer rapport
                    </Button>
                    {dataLoaded && (
                        <Button
                            variant="contained"
                            endIcon={<FileDownloadIcon />}
                            className={classes.submitBtn}
                            style={{ backgroundColor: "green" }}
                            onClick={handleExportToExcel}
                        >
                            Exporter til excel
                        </Button>
                    )}
                </div>

            </div>
            <div>
                <Label>Rapport fra d. {startDate.getDay()} {danishMonths[startDate.getMonth()]} {startDate.getFullYear()} til d. {endDate.getDay()} {danishMonths[endDate.getMonth()]} {endDate.getFullYear()}</Label>
                <div>
                    {(reportData) ? (
                        <div style={{ marginBottom: "10px" }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeader}>Titel</TableCell>

                                            {Object.keys(reportData).map((label) => {
                                                return <TableCell align="left" className={classes.tableHeader}>{danishMonths[label]}</TableCell>
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell align="left">Total</StyledTableCell>

                                            {Object.keys(reportData).map((label) => {
                                                return <StyledTableCell align="left" > {numberWithCommas(reportData[label]?.totalPrice)},- kr</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                        <StyledTableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell align="left">Antal tilmeldinger</StyledTableCell>

                                            {Object.keys(reportData).map((label) => {
                                                return <StyledTableCell align="left" >{reportData[label]?.totalAmount}</StyledTableCell>
                                            })}
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
                        : (
                            <div style={{ margin: "2px 0 10px 0" }}> Ingen data - tryk på "Generer rapport" </div>
                        )
                    }
                </div>
            </div>
            <Label>Tilmeldinger</Label>
            <div className={classes.body}>
                {(dataLoaded && filteredRegistrations?.length > 0) ? (
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>

                                    {selectedColumns && (
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeader}>Titel</TableCell>
                                            {selectedColumns.map((column) => {
                                                return <TableCell align="left" className={classes.tableHeader}>{column}</TableCell>
                                            })}
                                            <TableCell align="left" className={classes.tableHeader} >Kursus startdato</TableCell>
                                            <TableCell align="left" className={classes.tableHeader} >Tilmeldingsdato</TableCell>

                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>

                                    {filteredRegistrations.map((row) => (

                                        <StyledTableRow
                                            key={row.courseId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell>{row.title}</StyledTableCell>
                                            {selectedColumns && selectedColumns.map((column) => {
                                                return <StyledTableCell>{row[databaseToObjectDataMap[column]]}</StyledTableCell>
                                            })}
                                            <StyledTableCell>{moment(row.startDate).format("DD-MM-YYYY")}</StyledTableCell>
                                            <StyledTableCell>{moment(row.registrationDate).format("DD-MM-YYYY")}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ) : (
                    <div>
                        {generatingReport ? (
                            <Box sx={{ width: 300 }}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                            </Box>
                        ) : (
                            <div style={{ margin: "2px 0 10px 0" }}> Ingen data - tryk på "Generer rapport" </div>
                        )}

                    </div>
                )}
            </div>
        </div>
    )
}


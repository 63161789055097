import { Label, Panel, PanelType, PrimaryButton, DetailsList, DetailsListLayoutMode, SelectionMode, IconButton, Dialog, DialogFooter, DialogType, Spinner, SpinnerSize, DefaultButton, TextField } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { ICourseType, ICourseRelated } from "./DataTable";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getCourseTypeRelatedCourses, createCourseTypeBeforeAfter, deleteCourseTypeBeforeAfter, getCourseTypes } from "../api";
import "../styles/ContentPanel.css";

export default function CourseTypeRelatedsPanel(props: { courseType: ICourseType, onDismiss: () => void; }): JSX.Element {
    const [courseType, setCourseType] = useState<ICourseType | null>(props.courseType);
    const [courseTypesBefore, setCourseTypesBefore] = useState<ICourseType[]>([]);
    const [courseTypesAfter, setCourseTypesAfter] = useState<ICourseType[]>([]);
    const [availableCourses, setAvailableCourses] = useState<ICourseType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [newCourseBeforeAfter, setNewCourseBeforeAfter] = useState<ICourseRelated | null>(null);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [relations, setRelations] = useState<ICourseRelated[]>([]);
    const [dialogAction, setDialogAction] = useState<'add' | 'delete' | null>(null);
    const [courseToDelete, setCourseToDelete] = useState<ICourseType | null>(null);


    useEffect(() => {
        setIsLoading(true);
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
            fetchCourseTypeRelations(response.accessToken);
            setIsLoading(false);
        });
    }, [props]);

    const fetchCourseTypeRelations = async (accessToken: string) => {
        try {
            const relationResponse = await getCourseTypeRelatedCourses(accessToken, 0);

            if (!relationResponse || !relationResponse.data) {
                setError("Ingen kursusrelationer fundet.");
                setIsLoading(false);
                return;
            }

            const relations: ICourseRelated[] = relationResponse.data;
            setRelations(relations);

            const currentCourseRelations = relations.filter(rel => rel.kursustypeID === courseType.kursustypeID);

            const relatedCourseIds = currentCourseRelations.map(rel => rel.kursustypeID_FoerEfter);
            const coursesBeforeIds = currentCourseRelations.filter(rel => rel.foerEfter === 0).map(rel => rel.kursustypeID_FoerEfter);
            const coursesAfterIds = currentCourseRelations.filter(rel => rel.foerEfter === 1).map(rel => rel.kursustypeID_FoerEfter);

            const allCourses = await getCourseTypes(accessToken, 0);

            const coursesBefore = allCourses.data.filter(course => coursesBeforeIds.includes(course.kursustypeID));
            const coursesAfter = allCourses.data.filter(course => coursesAfterIds.includes(course.kursustypeID));
            const availableCourses = allCourses.data.filter(course => !relatedCourseIds.includes(course.kursustypeID) && course.kursustypeID !== courseType.kursustypeID);

            setCourseTypesBefore(coursesBefore);
            setCourseTypesAfter(coursesAfter);
            setAvailableCourses(availableCourses);
            setIsLoading(false);
        } catch (error) {
            console.error("Fejl ved hentning af kurser og relationer:", error);
            setError("Fejl ved hentning af kurser og relationer.");
            setIsLoading(false);
        }
    };


    const onAddCourse = async () => {
        if (!newCourseBeforeAfter) {
            setError("Fejl.");
            return;
        }

        setIsDialogLoading(true);
        try {
            await createCourseTypeBeforeAfter(token, newCourseBeforeAfter);
            fetchCourseTypeRelations(token);
            setNewCourseBeforeAfter(null);
            setIsDialogVisible(false);
        } catch (error) {
            console.error(error);
            setError("Fejl ved tilføjelse af kursus.");
        } finally {
            setIsDialogLoading(false);
        }
    };

    const onRemoveCourse = async (courseTypeID: number) => {
        if (!courseToDelete) {
            setError("Fejl.");
            return;
        }

        setIsDialogLoading(true);
        try {
            await deleteCourseTypeBeforeAfter(token, courseType.kursustypeID, courseToDelete.kursustypeID);
            fetchCourseTypeRelations(token);
            setCourseToDelete(null);
            setIsDialogVisible(false);
        } catch (error) {
            console.error(error);
            setError("Fejl ved fjernelse af kursus.");
        } finally {
            setIsDialogLoading(false);
        }
    };

    const showAddCourseDialog = (course: ICourseType, beforeAfter: number) => {
        setNewCourseBeforeAfter({
            kursustypeID: courseType.kursustypeID,
            kursustypeID_FoerEfter: course.kursustypeID,
            kursustypeFoerEfterID: 0,
            sortering: sortOrder || 0,
            foerEfter: beforeAfter
        });
        setDialogAction('add');
        setIsDialogVisible(true);
    };

    const showDeleteCourseDialog = (course: ICourseType) => {
        setCourseToDelete(course);
        setDialogAction('delete');
        setIsDialogVisible(true);
    };

    return (
        <Panel
            type={PanelType.largeFixed}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            closeButtonAriaLabel="Close"
            className="ContentPanel"
            styles={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: 0,
                },
                scrollableContent: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0,
                    overflowY: 'auto',
                    height: '100%'
                },
            }}
        >
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    backgroundColor: 'white',
                    paddingBottom: '1rem ',
                    marginTop: '-18px',
                    paddingTop: '1rem',
                    width: '90%',
                }}
            >
                <h4 style={{ margin: 0 }}>{props?.courseType.kursusTitel}</h4>
                <p>Administrer releterede kurser</p>
            </div>
            {!isLoading && courseType !== null && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <>
                        <Label>Kurser</Label>
                        <DetailsList
                            items={courseTypesBefore}
                            columns={[
                                {
                                    key: 'kursusTitel',
                                    name: 'Kurser før dette kursus',
                                    fieldName: 'førTitel',
                                    minWidth: 175,
                                    maxWidth: 300,
                                    onRender: (item: ICourseType) => (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>{item.kursusTitel}</span>
                                            <div style={{ gap: '1em', display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
                                                <TextField
                                                    defaultValue={relations.find(rel => courseType.kursustypeID === rel.kursustypeID && item.kursustypeID === rel.kursustypeID_FoerEfter)?.sortering.toString() || ''}
                                                    disabled
                                                />
                                                <IconButton
                                                    iconProps={{ iconName: 'Delete' }}
                                                    title="Fjern"
                                                    ariaLabel="Fjern"
                                                    onClick={() => showDeleteCourseDialog(item)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            ]}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />
                    </>
                    <DetailsList
                        items={courseTypesAfter}
                        columns={[
                            {
                                key: 'kursusTitel',
                                name: 'Kurser efter dette kursus',
                                fieldName: 'efterTitel',
                                minWidth: 175,
                                maxWidth: 300,
                                onRender: (item: ICourseType) => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span>{item.kursusTitel}</span>
                                        <div style={{ gap: '1em', display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
                                            <TextField
                                                defaultValue={relations.find(rel => courseType.kursustypeID === rel.kursustypeID && item.kursustypeID === rel.kursustypeID_FoerEfter)?.sortering.toString() || ''}
                                                disabled
                                            />
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                title="Fjern"
                                                ariaLabel="Fjern"
                                                onClick={() => showDeleteCourseDialog(item)}
                                            />
                                        </div>

                                    </div>
                                )
                            }
                        ]}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        selectionPreservedOnEmptyClick={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        styles={{ root: { marginBottom: '2rem' } }}
                    />
                    <>
                        <Label>Tilføj kurser</Label>
                        <DetailsList
                            items={availableCourses}
                            columns={[
                                {
                                    key: 'kursusTitel',
                                    name: 'Titel',
                                    fieldName: 'titel',
                                    minWidth: 200,
                                    onRender: (item: ICourseType) => (
                                        <span>{item.kursusTitel}</span>
                                    ),
                                },
                                {
                                    key: 'addSort',
                                    name: 'Rækkefølge',
                                    fieldName: 'addSort',
                                    minWidth: 100,
                                    onRender: (item: ICourseType) => (
                                        <div style={{ textAlign: 'right', display: 'block' }}>
                                            <TextField
                                                value={sortOrder.toString()}
                                                onChange={(e, newValue) => setSortOrder(Number(newValue))}
                                            />
                                        </div>
                                    ),
                                    styles: {
                                        cellTitle: { justifyContent: 'flex-end' },
                                    }
                                },
                                {
                                    key: 'addBefore',
                                    name: 'Tilføj før',
                                    fieldName: 'addBefore',
                                    minWidth: 100,
                                    onRender: (item: ICourseType) => (
                                        <div style={{ textAlign: 'right', display: 'block' }}>
                                            <IconButton
                                                iconProps={{ iconName: 'Add' }}
                                                title="Tilføj før"
                                                ariaLabel="Tilføj før"
                                                onClick={() => showAddCourseDialog(item, 0)}
                                            />
                                        </div>
                                    ),
                                    styles: {
                                        cellTitle: { justifyContent: 'flex-end' },
                                    }
                                },
                                {
                                    key: 'addAfter',
                                    name: 'Tilføj efter',
                                    fieldName: 'addAfter',
                                    minWidth: 100,
                                    onRender: (item: ICourseType) => (
                                        <div style={{ textAlign: 'right', display: 'block' }}>
                                            <IconButton
                                                iconProps={{ iconName: 'Add' }}
                                                title="Tilføj efter"
                                                ariaLabel="Tilføj efter"
                                                onClick={() => showAddCourseDialog(item, 1)}
                                            />
                                        </div>
                                    ),
                                    styles: {
                                        cellTitle: { justifyContent: 'flex-end' },
                                    }
                                }
                            ]}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />
                    </>
                    {error && (
                        <Alert severity="warning">{error}</Alert>
                    )}
                </div>
            )}

            <Dialog
                hidden={!isDialogVisible}
                onDismiss={() => setIsDialogVisible(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogAction === 'add' ? "Bekræft tilføjelse" : "Bekræft fjernelse",
                    subText: dialogAction === 'add' ? `Er du sikker på, at du vil tilføje denne relation?` : `Er du sikker på, at du vil fjerne denne relation?`,
                }}
                modalProps={{ isBlocking: true }}
            >
                {isDialogLoading ? (
                    <Spinner size={SpinnerSize.medium} />
                ) : (
                    <DialogFooter>
                        <PrimaryButton
                            text={dialogAction === 'add' ? "Tilføj" : "Fjern"}
                            onClick={dialogAction === 'add'
                                ? onAddCourse
                                : courseToDelete ? () => onRemoveCourse(courseToDelete.kursustypeID) : undefined
                            }
                            disabled={dialogAction !== 'add' && !courseToDelete}
                        />
                        <DefaultButton text="Annuller" onClick={() => setIsDialogVisible(false)} />
                    </DialogFooter>
                )}
            </Dialog>
        </Panel>
    );
}


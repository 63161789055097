import { createContext, useEffect, useState } from "react"
import { ICourseRegistration } from "./components/RegistrationsTable";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { AccountInfo } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";

const KurserContext = createContext<{
    account: AccountInfo[];
    roles: string[];
    currentRegistration: ICourseRegistration | null;
    handleSetCurrentRegistration: (emai: ICourseRegistration) => void;

}>({
    account: [],
    roles: [],
    currentRegistration: null,
    handleSetCurrentRegistration: (email) => { },
})

export function KurserFunction(props: any) {
    const [currentRegistration, setCurrentRegistration] = useState<ICourseRegistration | null>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated && !instance.getActiveAccount()) {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        } else {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then(response => {
                const decodedToken: any = jwtDecode(response.accessToken);
                const userRoles = decodedToken.roles || [];
                setRoles(userRoles);
            })
                .catch(e => console.log("Error acquiring token", e));
        }
    }, [isAuthenticated]);

    const handleSetCurrentRegistration = (email: ICourseRegistration) => {
        setCurrentRegistration(email);
    }

    const context = {
        account: accounts,
        roles,
        currentRegistration: currentRegistration,
        handleSetCurrentRegistration: handleSetCurrentRegistration,
    }

    return (

        <KurserContext.Provider value={context}>
            {props.children}

        </KurserContext.Provider>)
}

export default KurserContext;
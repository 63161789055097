import axios, { AxiosResponse } from 'axios';
import { ICourse , ICourseDay, IProgram, ICourseType, IProducttype, ICourseRelated} from './components/DataTable';
import { ICourseRegistration } from './components/RegistrationsTable';

const url = window.location.href.indexOf("localhost") !== -1 ? "https://localhost:7276/" : "https://4dkursusinterface.azurewebsites.net/"

export async function getPrograms(accessToken: string, inaktiv: boolean, all: boolean) {
    return axios.get(url, {
        headers: {
            Authorization: "Bearer " + accessToken,
        },
        params: {
            inaktiv: inaktiv,
            all: all
        }
    })
        .catch((error: any) => console.log(error));
}
export async function getTypes(accessToken: string) {
    return axios.get(url + "types", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function getCourseTypes(accessToken: string, id: number) {
    return await axios.get(url + "types/" + id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => {console.log(error); return null;});
}

export async function getCourseDates(accessToken: string, id: number) {
    return axios.get(url + "courses/" + id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => console.log(error));
}

export async function getCourseDays(accessToken: string, courseId: number) {
    return axios.get(url + "days/" + courseId.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => console.log(error));
}

export async function getAllCourseRegistrations(accessToken: string) {
    return axios.get(url + "registration", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error)
    });
}
export async function getPeriodCourseRegistrations(accessToken: string, postObject: any) {
    return axios.post(url + "registration/period", postObject, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function getLanguages(accessToken: string) {
    return axios.get(url + "languages", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return [];
    });;
}

export async function getLevels(accessToken: string) {
    return axios.get(url + "levels", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return [];
    });
}

export async function getCourseTemplates(accessToken: string) {
    return axios.get(url + "templates", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return [];
    });
}

export async function getProducttypes(accessToken: string) {
    return axios.get(url + "producttypes", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return null;
    });
}

export async function getProgramgroups(accessToken: string) {
    return axios.get(url + "programgroups", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return [];
    });
}

export async function getProgramRelations(accessToken: string, programId: number) {
    return await axios.get(url + "programCourses/" + programId.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return null;
    });
}

export async function getCourseTypeRelatedCourses(accessToken: string, courseId: number) {
    return await axios.get(url + "relatedCoursesTypes/" + courseId.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
    .catch((error: any) => {
        console.log(error);
        return null;
    });
}

export async function addOrUpdateCourseDate(accessToken: string, course: ICourse) {
    return axios.post(url, course, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    });
}
export async function addOrUpdateCourseDay(accessToken: string, day: ICourseDay) {
    return axios.post(url + "day", day, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function updateCourseContent(accessToken: string, type: ICourseType) {
    return axios.post(url + "type", type, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function updateRegistrationStatus(accessToken: string, registration: any) {

    return axios.post(url + "registration", registration, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function updateProgramContent(accessToken: string, program: IProgram) {
    return axios.post(url + "program", program, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function createNewCourseType(accessToken: string, type: ICourseType) {
    return axios.post(url + "newType", type, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function createNewProgram(accessToken: string, program: IProgram) {
    return axios.post(url + "newProgram", program, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}  

export async function createCourseProgramRelation(accessToken: string, courseId: number, programId: number) {
    return axios.post(url + "program/" + programId.toString() + "/course/" + courseId.toString(),{}, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function updateProducttype(accessToken: string, producttype: IProducttype) {
    return axios.post(url + "producttype", producttype, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function createProducttype(accessToken: string, producttype: IProducttype) {
    return axios.post(url + "newProducttype", producttype, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function createCourseTypeBeforeAfter(accessToken: string, relatedCourse: ICourseRelated) {
    return axios.post(url + "relatedCourseType", relatedCourse, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function addDatasheetLayout(accessToken: string, kursustypeId: number) {
    return axios.post(url + "addDatasheetLayout/" + kursustypeId.toString(),{}, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function deleteCourseTypeBeforeAfter(accessToken: string, courseId: number, relatedCourseId: number) {
    return axios.delete(url + "relatedCourseType/" + relatedCourseId.toString() + "/" + courseId.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function deleteCourseProgramRelation(accessToken: string, courseId: number, programId: number) {
    return axios.delete(url + "program/" + programId.toString() + "/course/" + courseId.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function deleteCourseDate(accessToken: string, id: number) {
    return axios.delete(url + id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function deleteCourseRegistration(accessToken: string, id: number) {

    return axios.delete(url + "Kurser/registration/" +id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function deletePeoplenetCourseRegistration(data: any) {
    
    return axios.put('https://peoplenet.dk/wp-json/pncourses/v1/delete-submission',  data)
    .catch((error: any) => console.log("Der skete et problem med at slette en tilmelding i Peoplenet endpoint. Error: " + error));
}
export async function getPeoplenetPeriodCourseRegistration(data: any) {
     
    return axios.post('https://peoplenet.dk/wp-json/pncourses/v1/get-period-submission',  data)
    .catch((error: any) => console.log("Der skete et problem med at hente tilmeldinger i Peoplenet endpoint for denne periode. Error: " + error));
}

export async function getAllPeoplenetCourseRegistrations() {
    return axios.get('https://peoplenet.dk/wp-json/pncourses/v1/get-submissions')
        .catch((error: any) => console.log("Der skete et problem med at hente tilmeldinger fra Peoplenet endpoint. Error: " + error));
}

export async function getAllPeoplenetCoursePrices() {
    return axios.get('https://peoplenet.dk/wp-json/pncourses/v1/get-course-prices')
        .catch((error: any) => console.log("Der skete et problem med at hente kursus priser fra Peoplenet endpoint. Error: " + error));
}

export async function updatePeoplenetRegistrationStatus( data: any) {
    return axios.put('https://peoplenet.dk/wp-json/pncourses/v1/post-submission', data)
        .catch((error: any) => console.log("Der skete et problem med at poste en tilmelding til Peoplenet endpoint. Error: " + error));
}